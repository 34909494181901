<template>
  <div>
    <a-modal
      title="收藏视频"
      :visible="visible"
      width="24%"
      :centered="true"
      :destroyOnClose="true"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <div class="flex items-center">
        收藏至：
        <a-cascader
          :options="folderList"
          v-model="selectValue"
          change-on-select
          @change="selectFavorite"
          expand-trigger="hover"
          style="width: 220px"
          placeholder="请选择上级收藏夹"
          :fieldNames="{
            label: 'folderName',
            value: 'folderId',
            children: 'sonCameraFavoriteFolders',
          }"
        />
        <!-- <a-select v-model="selectValue" style="width: 160px">
          <a-select-option
            :value="folder.folderId"
            v-for="folder in folderList"
            :key="folder.folderId"
          >
            {{ folder.folderName }}
          </a-select-option>
        </a-select> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import bus from '@/components/js/eventBus';

export default {
  name: 'CollectDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    cameraInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      confirmLoading: false,
      selectValue: [],
      folderList: [],
      folderLists: [],
      ids: [],
    };
  },
  computed: {
    ...mapState({
      homeFolder: (state) => state.home.homeFolder,
    }),
  },
  watch: {
    visible(nv) {
      if (nv) {
        this.getFolderLists();
        this.initData();
      }
    },
  },
  methods: {
    // initData() {
    //   this.getFolder().then(() => {
    //     this.selectValue = this.homeFolder.filter(
    //       (item) => item.whetherDefaultFolder === 1
    //     )[0].folderId;
    //   });
    // },
    selectFavorite(value, options) {
      console.log('value, options', value, options);
    },
    initData() {
      let params = {};
      this.$api.home.selectFolder(params).then((res) => {
        if (res.code === 200) {
          this.folderList = res.data;
          // this.selectValue = this.folderList.filter(
          //   (item) => item.whetherDefaultFolder === 1
          // )[0].folderId;
          // this.folderList.forEach((item) => {
          //   const match = this.folderLists.find(
          //     (obj) => obj.folderId === item.folderId
          //   );
          //   if (match) {
          //     item.folderName = match.folderName + '已收藏';
          //   }
          // });
          this.renameNodes(this.folderList, this.ids);
          console.log('item, index', this.folderList, this.ids);
        }
      });
    },
    getFolderLists() {
      let data = {
        cameraId: this.cameraInfo.cameraId,
      };
      this.$api.home.selectFolderCamera(data).then((res) => {
        if (res.code === 200) {
          this.folderLists = res.data;
          this.ids = this.folderLists.map((item) => item.folderId);
          console.log('this.ids', this.ids);
        }
      });
    },
    renameNodes(tree, ids) {
      // 递归函数来查找并修改名称
      function renameNode(node) {
        if (ids.includes(node.folderId)) {
          node.folderName = node.folderName + '(已收藏)'; // 修改名称
        }
        if (
          node.sonCameraFavoriteFolders &&
          node.sonCameraFavoriteFolders.length
        ) {
          node.sonCameraFavoriteFolders.forEach(renameNode); // 递归子节点
        }
      }
      tree.forEach(renameNode); // 开始查找并修改名称
    },
    handleCancel() {
      this.selectValue = [];
      this.$emit('closeCollect');
    },
    handleOk() {
      bus.$emit('closeTree');
      this.$emit(
        'sureCollect',
        this.selectValue[this.selectValue.length - 1],
        this.cameraInfo
      );
      this.selectValue = [];
      setTimeout(() => {
        bus.$emit('refreshFolderList');
      }, 1000);
    },
    ...mapActions(['getHomeFolder']),
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped></style>
