<template>
  <div class="cameras-sort-box">
    <!-- 排序头部 -->
    <div class="cameras-sort-top">
      <div class="cameras-sort-top-title">
        当前正在调整排序,请保存后再进行其他操作!
      </div>
      <div class="cameras-sort-top-but">
        <a-button
          style="
            border: 1px solid #ffffff;
            border-radius: 4px;
            background: transparent;
          "
          class="but"
          @click="onCancel"
          >取消</a-button
        >
        <a-button
          style="
            border: 1px solid #ffffff;
            border-radius: 4px;
            background: transparent;
          "
          class="ml16 but"
          @click="onReset"
          >重置</a-button
        >
        <a-button
          style="background: #079fda; border-radius: 4px"
          class="ml16 but"
          type="primary"
          @click="onSave"
          >保存排序</a-button
        >
      </div>
    </div>
    <!-- 排序详情 -->
    <div class="cameras-sort-bottom">
      <div class="cameras-sort-bottom-title">
        <div class="folder">
          <img src="@/assets/resource/collect-folder.png" />
        </div>
        <div class="cameras-data">{{ favoriteData.folderName }}</div>
      </div>
      <!-- 摄像机列表 -->
      <a-spin class="ma-spin" :spinning="loading"></a-spin>
      <div class="cameras-sort-bottom-main" v-if="favoriteList.length > 0">
        <draggable
          :list="favoriteList"
          animation="300"
          :forceFallback="true"
          @start="onStart"
          @end="onEnd"
          @updateSort="updateSort"
        ></draggable>
      </div>
    </div>
  </div>
</template>

<script>
import vuedraggable from '@/components/favorite-cameras-sort/vuedraggable/index.vue';
import bus from '@/components/js/eventBus';
export default {
  name: 'favoriteCamerasSort',
  props: {
    dataList: {
      type: Object,
      default: null,
    },
  },
  components: {
    draggable: vuedraggable,
  },
  data() {
    return {
      ishint: true, // 是否为组件销毁后需要提示
      favoriteData: this.dataList, // 需要排序的文件夹
      loading: true, // // 数据加载中
      favoriteList: [], // 摄像机数据
      favoriteSort: [], // 排序值
    };
  },

  mounted() {},
  destroyed() {
    if (this.ishint) {
      this.$message.warning(
        '摄像机排序未保存,切换页面后调整的排序将丢失恢复初始值。'
      );
    }
  },
  methods: {
    // 取消排序
    onCancel() {
      this.ishint = false;
      this.$emit('cancel');
    },
    // 重置排序
    onReset() {
      this.fetchList();
    },
    // 保存排序
    onSave() {
      // let sortList = this.sortResult(this.favoriteSort, this.favoriteList);
      if (!(this.favoriteList.length > 0)) {
        return false;
      }
      this.$api.home
        .updateFavoritesCameraSort(this.favoriteSort)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success('排序成功');
            bus.$emit('refreshFolderList');
            bus.$emit('closeTree');
            this.ishint = false;
            this.$emit('updateFavorite');
          }
        });
    },
    // 开始拖拽
    onStart() {},
    // 拖拽结束
    onEnd() {},
    // 更新拖拽后的值
    updateSort(data) {
      this.favoriteSort = data;
    },
    // 获取摄像机数据
    fetchList() {
      this.loading = true;
      this.favoriteList = [];
      let params = { folder: this.favoriteData?.folderId };
      this.$api.home.getFolderCamera(params).then((res) => {
        if (res.code === 200) {
          this.favoriteList = res.data;
          this.loading = false;
          console.log('排序获取摄像机数据h', this.favoriteList);
        }
      });
    },
    sortResult(sortable, list) {
      if (!(sortable?.length > 0 && list?.length > 0)) {
        return list;
      }
      let sortArr = [];
      sortable.map((i) => {
        list.map((item) => {
          if (i == item.favoriteId) {
            sortArr.push(item);
          }
        });
      });
      return sortArr;
    },
  },
  watch: {
    dataList: {
      immediate: true,
      deep: true,
      handler() {
        this.favoriteData = this.dataList;
        this.fetchList();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.cameras-sort-box {
  width: 100%;
  height: 100%;
  padding: 16px 0px;
  // border: 1px solid red;
  //   background: pink;
  .cameras-sort-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 20px);
    height: 104px;
    padding: 20px 0;
    margin: 0 10px;
    background: #e74825;
    border-radius: 2px;
    .cameras-sort-top-title {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      .cameras-sort-top-but {
        display: flex;
      }
    }
  }
  .cameras-sort-bottom {
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: calc(100% - 104px - 20px);
    // background: blue;
    .cameras-sort-bottom-title {
      width: 100%;
      height: 24px;
      padding: 0 10px 0 30px;
      //   border: 1px solid red;
      display: flex;
      align-items: center;
      .folder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17px;
        height: 13px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .cameras-data {
        width: calc(100% - 17px - 10px);
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .cameras-sort-bottom-main {
      width: 100%;
      height: calc(100% - 24px);
      //   margin-top: 15px;
      padding: 0 0 0 48px;
      //   border: 1px solid orange;
      overflow: hidden;
      overflow-y: auto;
      .cameras-sort-bottom-list {
        display: flex;
        align-items: center;
        height: 20px;
        margin-top: 15px;
        // border: 1px solid red;
        .cameras-sort-bottom-icon {
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          img {
            width: 18px;
            height: 18px;
          }
        }
        .cameras-sort-bottom-info {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
        }
        &:hover {
          cursor: move;
        }
      }
    }
    .ma-spin {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.ml16 {
  margin-left: 16px !important;
}
.but {
  &.ant-btn:focus,
  &.ant-btn:hover {
    color: #ffffff;
  }
}
</style>
