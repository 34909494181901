<template>
  <div class="vuedraggable-main">
    <div id="itxst" class="itxst">
      <div
        class="itxst-list flex items-center m-b-sm p-w-sm"
        v-for="(item, index) in list"
        :key="index"
        :data-id="item.favoriteId"
        :title="item.cameraNames"
      >
        <span
          :class="[
            'icon-style  m-r-xs flex-s',
            {
              online: item.cameraStatus === 1,
              offline: item.cameraStatus === 0,
              error: item.cameraStatus === 2,
              yunOffline: item.cameraStatus === 0 && $g.treeDefinate,
            },
          ]"
        ></span>
        <!--ellipsis-->
        <span
          class="flex-one move ellipsis"
          :class="{
            'text-lightgray line-througth': item.deleteStatus === 1,
          }"
          style="white-space: nowrap"
          >{{ item.cameraNames }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
export default {
  name: "vuedraggable",
  // components: {
  //   Sortable
  // },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    animation: {
      type: String,
      default: "",
    },
    forceFallback: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortable: null,
    };
  },

  mounted() {
    let that = this;
    //获取对象
    var el = document.getElementById("itxst");
    console.log("sortable", this.animation);
    //设置配置
    var ops = {
      animation: parseInt(this.animation) || 1000,
      //停靠位置的自定义样式
      ghostClass: "ghost",
      //选中元素的自定义样式
      chosenClass: "chosenClass",
      //忽略HTML5原生拖拽行为
      forceFallback: this.forceFallback,
      //拖动开始
      onStart: function (evt) {
        that.$emit("start", evt);
      },
      //拖动结束
      onEnd: function (evt) {
        that.$emit("end", evt);
        let sortResult = that.sortable.toArray();
        that.$emit("updateSort", sortResult);
      },
    };
    //初始化
    this.sortable = Sortable.create(el, ops);
    let sortResult = that.sortable?.toArray();
    this.$emit("updateSort", sortResult);
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.vuedraggable-main {
  width: 100%;
  // height: 100%;
  .itxst {
    .itxst-list {
      margin-top: 15px;
    }
  }
}
.line-througth {
  text-decoration: line-through;
}
.move {
  cursor: move;
}
// 拖拽样式
// 被替换元素的样式
.ghost {
  // border: solid 1px rgb(19, 41, 239) !important;
}
// 选中元素的样式
.chosenClass {
  //   background-color: #eee;
  //   opacity: 1;
  //   border: solid 1px red;
}
</style>
