<template>
  <div class="online-statis" style="height: 220px">
    <h4 class="relative text-white title m-b">在线率统计</h4>
    <img
      src="~@/assets/home/detail.png"
      class="detail pointer"
      title="查看详情"
      alt="详情"
      @click="openDetail"
      v-if="false"
    />
    <vue-scroll :ops="$root.scrollOpsY" style="height: calc(100% - 36px)">
      <div class="on-con relative">
        <span class="t-lf cor absolute"></span>
        <span class="t-rf cor absolute"></span>
        <span class="b-lf cor absolute"></span>
        <span class="b-rf cor absolute"></span>
        <div class="on-con-center">
          <online-chart
            :chartFormateData="chartFormateData"
          ></online-chart>
        </div>
      </div>
      <div class="statis-box flex" v-if="false">
        <div>
          <img
            src="~@/assets/home/online-statis.png"
            class="online-statis-img"
            alt=""
          />
          <div class="clear flex"></div>
          <span class="info-title flex"
            >整体
            <p class="num">
              {{
                roadData.onlineCount +
                roadData.offlineCount +
                dataList[1].onlineCount +
                dataList[1].offlineCount
              }}路
            </p>
          </span>
        </div>

        <div class="info">
          <div class="info-box">
            <span class="info-title">{{ roadData.name || "" }}</span>
            <div class="clear"></div>
            <span>
              {{ roadData.onlineCount + roadData.offlineCount }} /
              {{ roadData.onlineCount }} /
              <span class="green">{{ roadNum }}%</span></span
            >
            <a-progress :percent="roadNum" :show-info="false" />
          </div>

          <div class="info-box">
            <span class="info-title">{{ dataList[1].name }}</span>
            <div class="clear"></div>
            <span
              >{{ dataList[1].onlineCount + dataList[1].offlineCount }} /
              {{ dataList[1].onlineCount }}
              /
              <span class="green">{{ otherNum }}%</span></span
            >
            <a-progress :percent="otherNum" :show-info="false" />
          </div>
        </div>
      </div>

      <!-- <div class="flex items-center box-info text-lightblue m-t-md">
        <div class="flex-one text-center m-r pointer">
          <span class="text-white f32 m-r-xs">58</span>人正在调取视频
        </div>
        <div class="flex-one text-center pointer" @click="goOutProvince()">
          <span class="text-white f32 m-r-xs">18</span>省正在调取视频
        </div>
      </div> -->
    </vue-scroll>
    <detail-dialog
      ref="childRef"
      :visible="detailVisible"
      @close="closeDialog"
      v-if="detailVisible"
    />
  </div>
</template>

<script>
import OnlineChart from "./OnlineChart";
import detailDialog from "./detail-dialog";

export default {
  name: "OnlineStatis",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dataList: [],
      otherNum: null,
      roadNum: null,
      otherData: {},
      roadData: {},
      detailVisible: false,
    };
  },
  components: {
    OnlineChart,
    detailDialog,
  },
  computed: {
    chartFormateData() {
      let total = 0,
        offline = 0,
        online = 0,
        inerror = 0;
      this.data.forEach((vo) => {
        total += vo.total;
        offline += vo.offline;
        online += vo.online;
        inerror += vo.inerror;
      });

      return { total, offline, online, inerror };
      /* this.$set(this.chartFormateDataObj, 'total', total);
      this.$set(this.chartFormateDataObj, 'offline', offline);
      this.$set(this.chartFormateDataObj, 'online', online);
      this.$set(this.chartFormateDataObj, 'inerror', inerror); */
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getOnlineRate();
      setInterval(() => {
        this.getOnlineRate();
        if (this.$refs.childRef) {
          this.$refs.childRef.getOnlineTable();
        }
      }, 3 * 60 * 1000);
    });
  },
  watch: {
    /* data: {
      handler() {
        this.chartFormateData();
      },
      deep: true,
    }, */
  },
  methods: {
    goOutProvince() {
      this.$router.push({ path: "/home/outside-province-video" });
    },
    getOnlineRate() {
      let params = {};
      this.$api.home.getOnlineRate(params).then((res) => {
        if (res.code === 200) {
          this.$nextTick(() => {
            this.dataList = res.data;
            this.otherData = this.dataList[1];
            this.roadData = this.dataList[0];
            this.roadNum = parseFloat(this.dataList[0].onlineRate);
            this.otherNum = parseFloat(this.dataList[1].onlineRate);
            console.log(" this.dataList ", this.dataList);
          });
        }
      });
    },
    openDetail() {
      this.detailVisible = true;
    },
    closeDialog() {
      this.detailVisible = false;
    },
  },
};
</script>

<style scoped>
.online-statis {
  height: calc(100% - 340px);
  width: 300px;
}
.online-statis-img {
  width: 80px;
  height: 70px;
  margin: 30px 20px 20px 20px;
}
.detail {
  width: 20px;
  height: 20px;
  float: right;
}
.info-box {
  padding: 10px 0;
}
.title {
  padding-left: 12px;
}
.title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 19px;
  background: #96b9e4;
}
.on-con {
  border-top: 1px dashed #28456a;
  border-bottom: 1px dashed #28456a;
}
.on-con .cor {
  border-color: #96b9e4;
  width: 9px;
  height: 9px;
}
.green {
  color: rgb(8, 226, 8);
}
.on-con-center {
  height: 100%;
}
.box-info > div {
  height: 64px;
  line-height: 64px;
  background: linear-gradient(
    90deg,
    rgba(46, 86, 142, 0.5),
    rgba(25, 45, 80, 0.5)
  );
}
.info-title {
  font-size: 16px;
  font-weight: 700;
}
.num {
  font-size: 14px;
  font-weight: 400;
  padding: 1px 0 0 8px;
}
</style>
