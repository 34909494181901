<template>
  <div class="favorite-tree-view">
    <div class="tree-box-folder">
      <!-- 树形结构 -->
      <a-tree
        ref="tree"
        class="tree-modality"
        :expanded-keys="expandedKeys"
        :tree-data="homeFolderCamera"
        :replaceFields="{
          key: 'key',
          title: 'folderName',
          children: 'sonCameraFavoriteFolders',
        }"
        :selected-keys="selectedKeys"
        @expand="onExpand"
        :checkable="treeCheckable"
        :checkedKeys="checkedKeyAryy"
        @select="onSelect"
        draggable
        @drop="onDrop"
      >
        <!-- :load-data="onLoadCameraSelectData" -->
        <!-- :auto-expand-parent="autoExpandParent" -->
        <!-- @check="onCheck" -->
        <!-- <a-icon slot="switcherIcon" class="select-icon" />
        <a-icon slot="customtitle" class="select-icon" /> -->
        <template slot="more" slot-scope="tags">
          <img
            v-if="tags.sonCameraFavoriteFolders"
            src="@/assets/favorite/icon-favorite-more.png"
          />
          <img v-else src="@/assets/resource/icon-project.png" />
        </template>
        <template slot="more" slot-scope="tags">
          <div class="folder">
            <!-- <div class="flex full-width folder-list" :style="getTreeWd"> -->
            <div class="name" :style="getTreeWd">
              <div class="folder-img"></div>
              <p>
                {{ tags.folderName }}({{ tags.onlineCountStr }}/{{
                  tags.cameraNumber
                }})
              </p>
              <div
                class="flex pointer m-l-sm click-btn"
                v-if="selectedKey == tags.folderId && isSelect"
              >
                <p @click="submit(tags)">
                  <img
                    src="@/assets/resource/submit-btn.png"
                    width="17"
                    class="m-r-xs"
                  />
                </p>
                <p @click="close(tags)">
                  <img
                    src="@/assets/resource/close-btn.png"
                    width="17"
                    class="m-r-xs"
                  />
                </p>
              </div>
              <div class="flex action-pull-down" v-show="!isMore">
                <div class="pull-down-main">
                  <a-dropdown
                    placement="bottomRight"
                    overlayClassName="type-menu-dropdown"
                  >
                    <a class="ant-dropdown-link show"></a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item @click="projectHandle(tags)"
                          >列表播放</a-menu-item
                        >
                        <a-menu-item
                          v-if="!tags.whetherDefaultFolder"
                          @click="handleEditFavorit(tags)"
                          >编辑</a-menu-item
                        >
                        <a-menu-item @click="addFavorite(tags)"
                          >添加子级</a-menu-item
                        >
                        <a-menu-item @click="favoriteCamerasSort(tags)"
                          >摄像机排序</a-menu-item
                        >
                        <a-menu-item
                          v-if="!tags.whetherDefaultFolder"
                          @click="favoriteSort('top', tags)"
                          >置顶</a-menu-item
                        >
                        <a-menu-item
                          v-if="!tags.whetherDefaultFolder"
                          @click="favoriteSort('bottom', tags)"
                          >置底</a-menu-item
                        >
                        <a-menu-item @click="batchDelete(tags)"
                          >批量取消收藏</a-menu-item
                        >
                        <a-menu-item
                          v-if="!tags.whetherDefaultFolder"
                          @click="deleteFolder(tags.folderId, tags.folderName)"
                          >删除</a-menu-item
                        >
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="customtitle" slot-scope="tags">
          <div class="flex full-width folder-list">
            <span
              :class="{
                'm-r-xs m-top6 icon-style m-t-ms relative': true,
                online: String(tags.cameraStatus) === '1',
                yunOffline: String(tags.cameraStatus) === '0',
                error: String(tags.cameraStatus) === '2',
              }"
              class="status-box"
            ></span>
            <span
              :style="getTreeListWd"
              class="flex-one pointer ellipsis tree-title"
              :class="{
                'text-lightgray line-througth': tags.deleteStatus === 1,
              }"
              @click="selectShot(tags)"
              :title="tags.folderName"
              >{{ tags.folderName }}</span
            >
            <div class="title-btn">
              <!-- <a-icon
                type="arrow-up"
                title="上移"
                class="pointer m-l-sm m-top4"
                :class="{ disabled: tags.cameraIndex === 0 }"
                @click="upHandle(tags, tags.cameraIndex)"
              />
              <a-icon
                type="arrow-down"
                title="下移"
                class="pointer m-l-sm m-top4"
                :class="{
                  disabled: tags.cameraIndex == tags.cameraTotal - 1,
                }"
                @click="downHandle(tags, tags.cameraIndex)"
              /> -->
              <i
                class="icon-collect current m-l-sm pointer m-top4"
                title="取消收藏"
                v-if="ope"
                @click="cancelCollectHandle(tags, tags.folderId)"
              />
            </div>
          </div>
        </template>
      </a-tree>
      <div @click="clearState" style="height: 50%; widht: 100%"></div>
    </div>
    <favorit-toggle
      :visible="favoritVisible"
      :edit="isEdit"
      :folderId="folderId"
      :folderName="folderName"
      :isSon="isSon"
      :folderInfo="folderInfo"
      :parentData="parentData"
      @close="closeFavorit"
      @sure="sureFavorit"
      @clear="clearState"
    />
    <!--收藏夹投屏-->
    <favorite-camera-group-setting
      v-if="visibleGroupSetting"
      ref="favoriteCameraGroupSetting"
      :visible="visibleGroupSetting"
      @close="closeGroupSettingHandle"
      @currentScreen="currentScreenHandle"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
// import FavoritToggle from '@/components/FavoritToggle';
import FavoritToggle from '@/components/addFavoritToggle';
import FavoriteCameraGroupSetting from '@/components/FavoriteCameraGroupSetting';
import { CODE_OK } from '@/request/config_code';
import { getStorage, setStorage } from '@/tools';
import bus from '@/components/js/eventBus';
// import difference from 'lodash-es/difference';
// import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'favorite',
  props: {
    ope: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FavoritToggle,
    FavoriteCameraGroupSetting,
  },
  computed: {
    ...mapState({
      cameraCollectList: (state) => state.collect.cameraCollectList,
      screenShotSearchParams: (state) => state.resource.screenShotSearchParams,
      amapIns: (state) => state.home.amapIns,
      treeListWd: (state) => state.home.treeListWd,
      // homeFolderCamera: (state) => state.home.homeFolderCamera,
    }),
    getTreeListWd() {
      let wd = this.treeListWd - 146;
      return { width: `${wd}px` };
    },
    getTreeWd() {
      let wd = this.treeListWd - 55;
      return { width: `${wd}px` };
    },
  },
  data() {
    return {
      treeData: [],
      homeFolderCamera: [],
      checkedList: [],
      favoritVisible: false,
      isEdit: false,
      folderId: '',
      folderName: '',
      isSon: false,
      visibleGroupSetting: false, // 列表播放设置窗口显隐
      selectArry: [],
      selectFolder: null,
      favoriteList: [], // 摄像机数据
      treeCheckable: false,
      checkedKeyAryy: [],
      expandedKeys: [],
      // autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      selectedKey: null,
      recordMark: {},
      isSelect: false,
      folderInfo: {},
      parentData: null,
      loadedKeys: [], // 已经加载的节点
      isLoadData: true, // 是否重新加载数据
      isSort: false,
      isMore: true,
      children: true,
      parentNode: null,
      selectData: {},
      isLeafs: true,
      newArry: [],
      sonLevel: [],
    };
  },

  mounted() {
    this.fetchTree();
    // this.getHomeDefaultCamera();
    // this.homeFolderCamera.forEach((item) => {
    //   item.children = [];
    //   item.isShow = false;
    //   // item.cameraNames = item.folderName;
    //   // console.log('item', item);
    // });
  },
  beforeMount() {
    bus.$on('refreshFolderList', this.fetchTree);
    bus.$on('closeTree', this.closeExpand);
    // bus.$on('openEditFavorit', this.handleEditFavorit(this.recordMark));
  },
  watch: {
    checkedKeys(val) {
      console.log('onCheck', val);
    },
  },
  created() {},
  methods: {
    ...mapMutations([
      'setCameraTab',
      'setScreenShotSearchParams',
      'setSelectCameraList',
      'setSessionCurrentCamera',
    ]),
    ...mapActions([
      'getCollectList',
      'deleteFolderCollectItem',
      'getScreenShotInfo',
      'getHomeDefaultCamera',
      'getHomeFolderCamera',
      'getHomeAllFolderCamera',
      'deleteFolderAction',
    ]),
    onDragEnter(info) {
      console.log(info);
      // expandedKeys 需要受控时设置
      // this.expandedKeys = info.expandedKeys
    },
    // 递归查找并替换树形数据中的指定对象的sort
    replaceNameById(nodes, key, sort) {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].key === key) {
          nodes[i].sort = sort;
          return true; // 找到并替换后返回true
        }
        if (
          nodes[i].sonCameraFavoriteFolders &&
          nodes[i].sonCameraFavoriteFolders.length > 0
        ) {
          // 递归查找子节点
          const found = this.replaceNameById(
            nodes[i].sonCameraFavoriteFolders,
            key,
            Number(sort)
          );
          if (found) return true; // 如果子节点中找到了，则返回true
        }
      }
      return false; // 未找到指定id的节点
    },
    // 节点拖拽
    onDrop(info) {
      console.log(info);
      if (!info.dragNode.dataRef.key) {
        this.$message.error('摄像机无法拖拽');
        return;
      }
      if (info.node.dataRef.levelVal != info.dragNode.dataRef.levelVal) {
        this.$message.error('收藏夹不同等级无法拖拽排序！');
        return;
      }
      if (!info.dropToGap) {
        this.$message.error('收藏夹不同等级无法拖拽排序！');
        return;
      }
      if (info.dragNode.dataRef.folderName == '我的收藏夹') {
        this.$message.error('该收藏夹暂不支持拖动');
        return;
      }
      const dropKey = info.node.eventKey;
      const dragKey = info.dragNode.eventKey;
      const replaceSort = info.node.dataRef.sort;
      const dropPos = info.node.pos.split('-');
      // 根据key查找数组里的对象并替换排序值
      this.replaceNameById(
        this.homeFolderCamera,
        info.dragNode.dataRef.key,
        replaceSort
      );
      // const selectF = this.homeFolderCamera.findIndex(
      //   (item) => item.key === info.dragNode.dataRef.key
      // );
      // if (selectF !== -1) {
      //   this.homeFolderCamera.splice(selectF, 1, {
      //     ...this.homeFolderCamera[selectF],
      //     sort: replaceSort,
      //   });
      // }
      const dropPosition =
        info.dropPosition - Number(dropPos[dropPos.length - 1]);
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr);
          }
          if (item.sonCameraFavoriteFolders) {
            return loop(item.sonCameraFavoriteFolders, key, callback);
          }
        });
      };
      const data = [...this.homeFolderCamera];

      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (!info.dropToGap) {
        // if (info.node.dataRef.levelVal == info.dragNode.dataRef.levelVal) {
        this.$message.error('收藏夹不同等级无法拖拽排序！');
        return;
        // }
        // Drop on the content
        // loop(data, dropKey, (item) => {
        //   item.sonCameraFavoriteFolders = item.sonCameraFavoriteFolders || [];
        //   // where to insert 示例添加到尾部，可以是随意位置
        //   item.sonCameraFavoriteFolders.push(dragObj);
        // });
      } else if (
        (info.node.children || []).length > 0 && // Has children
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        // loop(data, dropKey, (item) => {
        //   item.sonCameraFavoriteFolders = item.sonCameraFavoriteFolders || [];
        //   // where to insert 示例添加到尾部，可以是随意位置
        //   item.sonCameraFavoriteFolders.unshift(dragObj);
        // });
      } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          this.sonLevel = ar;
          i = index;
        });
        if (dropPosition === -1) {
          ar?.splice(i, 0, dragObj);
        } else {
          ar?.splice(i + 1, 0, dragObj);
        }
      }
      this.homeFolderCamera = data;
      // if (info.dragNode.dataRef.key) {
      if (info.dragNode.dataRef.levelVal == '1') {
        this.newArry = this.homeFolderCamera.slice(1);
      } else {
        this.newArry = this.sonLevel;
      }
      this.newArry.map((item, index) => {
        item.sort = index;
      });
      // } else {
      //   this.$message.error('摄像机无法拖拽');
      // }
      this.$api.home.folderSort(this.newArry).then((data) => {
        if (data.code === 200) {
          this.$message.success('收藏夹排序成功');
        } else {
          this.$message.error(data.message);
        }
      });
    },
    subName(name) {
      return name.length > 10 ? name.slice(0, 10) + '...' : name;
    },

    // 展开
    expandFolder(folder) {
      this.selectFolder = folder;
      if (!folder.show) {
        let data = {
          folder: folder.folderId,
        };
        this.getHomeFolderCamera(data).then(() => {
          folder.show = !folder.show;
        });
        return;
      }
      folder.show = !folder.show;
    },
    //投屏
    projectHandle(folder, e) {
      e && e.stopPropagation();
      // if (!parseInt(folder.cameraNumber) > 0) {
      //   this.$message.warning('暂无可播放的收藏摄像机');
      //   return false;
      // }
      let cameraInfoStore = getStorage('collectCamera');
      console.log('cameraInfoStorecameraInfoStore', cameraInfoStore, folder);
      // this.getHomeDefaultCamera();
      this.visibleGroupSetting = true;
      this.folderProId = folder.folderId;
      this.folderName = folder.folderName
    },
    // 投屏窗口关闭事件
    closeGroupSettingHandle() {
      this.visibleGroupSetting = false;
    },
    // 投屏窗口确定事件
    // currentScreenHandle(curScree) {
    //   let paramsObj = {
    //     folder: this.folderProId,
    //   };
    //   console.log('投屏窗口确定事件1122221333', paramsObj);
    //   this.$refs['favoriteCameraGroupSetting'].setLoading(true);
    //   this.getHomeFolderCamera(paramsObj).then((res) => {
    //     if (res.code === CODE_OK) {
    //       this.$refs['favoriteCameraGroupSetting'].setLoading(false);
    //       let camerasList = res.data
    //         .filter((item) => item.deleteStatus !== 1)
    //         .map((item) => {
    //           return item;
    //         });
    //       let palyState = {
    //         camerasList: camerasList,
    //         pageChecked: curScree.pageChecked,
    //         pictureInit: 1,
    //         litTit: curScree.litTit,
    //         timeSecond: curScree.interval,
    //       };
    //       this.visibleGroupSetting = false;
    //       // 开启投屏
    //       const uuid = uuidv4();
    //       const inspectionList =
    //         JSON.parse(localStorage.getItem('inspectionList')) || {};
    //       inspectionList[uuid] = palyState;
    //       localStorage.setItem(
    //         'inspectionList',
    //         JSON.stringify(inspectionList)
    //       );
    //       // 存储投屏值
    //       if (!curScree.pageChecked) {
    //         const replaceProjection = {
    //           name: this.folderName,
    //           litTit: curScree.litTit,
    //           uuid: uuid,
    //           screenNum: curScree.screenNum,
    //         };
    //         const replaceProjectionList =
    //           JSON.parse(sessionStorage.getItem('replaceProjectionList')) || [];
    //         replaceProjectionList.unshift(replaceProjection);
    //         sessionStorage.setItem(
    //           'replaceProjectionList',
    //           JSON.stringify(replaceProjectionList)
    //         );
    //       }
    //       let interval = curScree.interval
    //       setStorage('collectCamera', { interval, arr: camerasList });
    //       console.log('立即播放', palyState, uuid, inspectionList, window);
    //       // window.open() 方法会打开一个新窗口或标签页，第二个参数: 包含一个指向 https://xxx.com 的页面。第二个参数 'name'：给新窗口命名，第三个参数 'width=600,height=400' 用于指定新窗口的大小
    //       window.open(`/#/${curScree.mode}-window?sign=${uuid}`);
    //     }
    //   });
    // },
     // 投屏窗口确定事件
    currentScreenHandle(curScree) {
      let currentScreen = curScree.mode;
      let tit = curScree.litTit;
      let interval = curScree.interval;
      // this.visibleGroupSetting = false;
      // console.log(currentScreen, tit, this.projectDataList);
      let { href } = this.$router.resolve({
        path: `/${currentScreen}-window${tit}`,
      });
      let paramsObj = {
        folder: this.folderProId,
      };
      this.getHomeAllFolderCamera(paramsObj).then((res) => {
        if (res.code === CODE_OK) {
          this.projectDataList = res.data
            .filter((item) => item.deleteStatus !== 1)
            .map((item) => {
              item.cameraName = item.cameraNames;
              return item;
            });
          this.visibleGroupSetting = false;
          window.open(`${href}?isCollect=1`, "_blank");
          setStorage("collectCamera", { interval, arr: this.projectDataList });
        }
      });
    },
    // 收藏夹修改
    handleEditFavorit(item) {
      console.log('编辑的收藏夹', item);
      this.isSon = false;
      if (!this.hasPermission(109210101011)) {
        this.permissionWarning();
        return;
      }
      const arry = [];
      this.findParent(this.homeFolderCamera, item.folderId, arry);
      this.folderInfo = item;
      this.favoritVisible = true;
      this.isEdit = true;
      this.getHomeDefaultCamera();
      this.folderId = item.folderId;
      this.folderName = item.folderName;
      console.log(item, this.folderId, this.folderName);
    },
    findParent(list, id, arr) {
      for (let item of list) {
        if (item.folderId === id) {
          arr.push(item.folderId); //要id就push item.id, 要对象就push item
          this.parentData = arr[0];
          return true;
        }
        if (
          item.sonCameraFavoriteFolders &&
          item.sonCameraFavoriteFolders.length > 0
        ) {
          let isFind = this.findParent(item.sonCameraFavoriteFolders, id, arr);
          if (isFind) {
            arr.push(item.folderId); //要id就push item.id, 要对象就push item
            this.parentData = arr[1];
            return true;
          }
        }
      }
      return false;
    },
    // 收藏夹排序(置顶/置底)
    favoriteSort(type, val) {
      console.log('favoriteSort', type, val);
      let sort = null;
      let msg = '';
      if (type == 'top') {
        sort = 1; // 置顶
        msg = '置顶';
      }
      if (type == 'bottom') {
        sort = -1; // 置底
        msg = '置底';
      }
      const data = {
        folderId: val.folderId, // 收藏夹id
        parentFolderId: val.parentFolderId, // parentFolderId
        sort: sort, // 排序
      };
      this.$api.home.updateSortFavorite(data).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(`${msg}成功`);
          this.fetchTree();
        }
      });
    },
    // 摄像机排序
    favoriteCamerasSort(val) {
      console.log('favoriteCamerasSort', val);
      this.$emit('favoriteCamerasSortOpen', { ...val });
      this.getHomeDefaultCamera();
    },
    batchCancel(val) {
      if (val.cameraNumber < 1) {
        this.$message.warning('收藏夹下无摄像机');
        return;
      }
      this.$emit('favoriteCamerasCancelOpen', { ...val });
      this.getHomeDefaultCamera();
    },
    // 新增子级
    addFavorite(val) {
      console.log('val', val);
      this.favoritVisible = true;
      this.isSon = true;
      this.isEdit = false;
      this.folderId = val.folderId;
      this.folderName = val.folderName;
    },
    closeFavorit() {
      this.favoritVisible = false;
    },
    sureFavorit() {
      this.favoritVisible = false;
      this.upTreeData();
      // this.$message.success(`${this.isEdit ? '修改' : '新增'}收藏夹成功`);
    },
    // 收藏夹删除
    deleteFolder(folderId, folderName) {
      if (!this.hasPermission(109210101012)) {
        this.permissionWarning();
        return;
      }
      this.getHomeDefaultCamera();
      let data = {
        folderId,
        whetherDefaultFolder: 0,
        folderName,
      };
      this.$confirm({
        title: '提示',
        content: '确认删除该收藏文件夹吗?',
        onOk: () => {
          this.deleteFolderAction(data);
          this.upTreeData();
        },
        onCancel: () => {},
      });
    },
    // 收藏摄像机调阅
    selectShot(item) {
      if (this.ope) {
        // console.log(item);
        if (item.deleteStatus === 1) {
          this.$message.error('摄像机已删除，无法调阅该摄像机!');
          return;
        }
        if (item.otherOrganizationId === 1) {
          this.$message.error('暂无观看权限！');
          return;
        }
        let cameraInfo = item;
        cameraInfo.lonlat = [cameraInfo.longitude, cameraInfo.latitude];
        /*   if (cameraInfo.cameraStatus == 1) { */
        this.amapIns.cameraInfoWindowShow({
          data: {
            position: [cameraInfo.longitude, cameraInfo.latitude],
            onlineStatus: cameraInfo.status,
            cameraName: cameraInfo.cameraName,
            cameraId: cameraInfo.cameraId,
            cameraNum: cameraInfo.cameraNum,
            organizationId: cameraInfo.organizationId,
            regionCode: cameraInfo.regionCode,
            roadId: cameraInfo.roadId,
            kmPile: cameraInfo.kmPile,
            hmPile: cameraInfo.hmPile,
            classify: cameraInfo.classify,
            cameraType: cameraInfo.cameraType,
            zoomlevel: cameraInfo.zoomlevel,
            source: cameraInfo.source,
            khPile: cameraInfo.kmHmPile,
            collectFlag: 1,
            /* resolutionValue: cameraInfo.organizationId.startsWith('9901004075')
              ? 1
              : 0, */
            resolutionValue:
              cameraInfo.transcodingId === '1354354698693513216' ? 1 : 0,
            lonlat: [cameraInfo.longitude, cameraInfo.latitude],
          },
        });
        if (this.$g.isDepart) {
          this.amapIns.addActCameraIcon(cameraInfo);
        } else {
          this.amapIns.addVideoMarker(cameraInfo);
        }
        /*   } else {
          this.$message.error('该摄像机不在线，不能拉流！');
        } */
        return;
      }
      let data = {
        ...this.screenShotSearchParams,
        ...{ cameraId: item.cameraId },
      };
      this.setScreenShotSearchParams(data);
      this.getScreenShotInfo(data);
    },
    // 上移
    // eslint-disable-next-line no-unused-vars
    upHandle(item, cameraIndex) {
      // this.isSort = true;
      if (cameraIndex === 0) {
        return;
      }
      let tempItem = this.favoriteList[cameraIndex - 1];
      let copyItem = JSON.parse(JSON.stringify(item));
      let tempSort = copyItem.sort;
      item.sort = tempItem.sort;
      // tempItem.sort = tempSort;
      // this.$set(this.favoriteList, cameraIndex - 1, item);
      // this.$set(this.favoriteList, cameraIndex, tempItem);
      // console.log('folder, item, cameraIndex', this.favoriteList, item.sort);
      let params = {
        favoriteId: item.favoriteId,
        folderId: item.folderId,
        sort: tempSort - 1,
      };

      this.$api.home.moveUpFavorite(params).then((res) => {
        const { code } = res;
        if (code !== CODE_OK) {
          this.$message.error('接口调用失败');
        }
        if (code == 200) {
          this.$message.success('收藏摄像机排序成功！');
          // this.onLoadCameraSelectData(this.parentNode);
          // this.onLoadCameraSelectData(this.recordMark);
          // console.log('this.parentNode', this.recordMark);
        }
      });
    },
    // 下移
    // eslint-disable-next-line no-unused-vars
    downHandle(item, cameraIndex) {
      // if (cameraIndex === item.cameraList.length - 1) {
      //   return;
      // }
      let tempItem = this.favoriteList[cameraIndex + 1];
      let copyItem = JSON.parse(JSON.stringify(item));
      let tempSort = copyItem.sort;
      item.sort = tempItem.sort;
      tempItem.sort = tempSort;
      // this.$set(folder, cameraIndex + 1, item);
      // this.$set(folder, cameraIndex, tempItem);
      // this.$forceUpdate();
      let params = {
        favoriteId: item.favoriteId,
        folderId: item.folderId,
        sort: copyItem.sort,
      };
      this.$api.home.moveDownFavorite(params).then((res) => {
        const { code } = res;
        if (code !== CODE_OK) {
          this.$message.error('接口调用失败');
        }
        if (code == 200) {
          this.$message.success('收藏摄像机排序成功！');
        }
      });
    },
    // 取消收藏
    cancelCollectHandle(item, folderId) {
      console.log('item, folderId', item, folderId);
      if (!this.hasPermission(109210101014)) {
        this.permissionWarning();
        return;
      }
      this.$confirm({
        title: '提示',
        content: '确认取消收藏该路摄像机?',
        onOk: () => {
          let params = {
            favoriteId: item.favoriteId,
          };
          let obj = {
            params,
            instructionsDel: {
              module: '重点关注',
              page: '收藏列表',
              feature: '取消收藏',
              description: `取消收藏: ${item.cameraName}`,
            },
          };
          this.deleteFolderCollectItem(obj).then((data) => {
            if (data.code === 200) {
              this.$message.success('取消收藏成功！');
              item.folderId = folderId;
              this.upTreeData();
              // this.$set(item, 'hasFavorited', false);
              // this.$set(item.cameraList);
              // this.setSessionCurrentCamera(item);

              // setSessionStorage('currentCamera', item);
            }
          });
        },
        onCancel: () => {},
      });
    },
    upTreeData() {
      // if (this.isLoadDatax) {
      // this.expandedKeys = [];
      // this.homeFolderCamera = [];
      // this.flatDirTree = [];
      // // }
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.fetchTree();
      //   }, 500);
      // });
      // setTimeout(() => {
      this.expandedKeys = [];
      this.homeFolderCamera = [];
      this.flatDirTree = [];
      this.fetchTree();
      // this.onLoadCameraSelectData(this.recordMark);
      //   this.selectedKeys = [];
      // }, 500);
    },
    // 批量选择
    onChanges(e) {
      console.log('eee', e);
      this.selectArry = e;
    },
    onCheckAllChange(e) {
      console.log('eeeeeq', e);
    },
    // 批量取消
    batchDelete(item) {
      console.log('item', item);
      this.treeCheckable = true;
      this.isSelect = true;
      this.selectedKey = item.folderId;
      this.isMore = false;
      item.isShow = !item.isShow;
      // if (!item.show) {
      //   this.expandFolder(item);
      // } else {
      //   item.show = false;
      //   this.expandFolder(item);
      // }
    },
    submit(item) {
      console.log(item);
      this.$confirm({
        title: '提示',
        content: '确认取消收藏所选摄像机吗?',
        onOk: () => {
          // let obj = {
          //   requests: this.selectArry,
          //   instructionsDel: {
          //     module: '我的收藏',
          //     page: '收藏列表',
          //     feature: '批量取消收藏',
          //   },
          // };
          // console.log('objobj', obj);
          this.$api.home
            .batchFavorite(
              this.selectArry.filter(
                (number) => !number.toString().startsWith('0') && !isNaN(number)
              )
            )
            .then((data) => {
              if (data.code === 200) {
                // this.expandFolder(this.selectFolder);
                this.$message.success('批量取消收藏成功！');
                this.$nextTick(() => {
                  this.upTreeData();
                  // this.fetchTree();
                  // this.onLoadCameraSelectData(this.recordMark);
                  this.close(item);
                });
              }
            });
        },
        onCancel: () => {},
      });
    },
    close(item) {
      console.log('item', item);
      this.isSelect = false;
      this.treeCheckable = false;
      this.isMore = true;
      this.checkedKeyAryy = [];
      // item.isShow = false;
      // if (!item.show) {
      //   this.expandFolder(item);
      // } else {
      //   item.show = false;
      //   this.expandFolder(item);
      // }
    },
    // 重新加载数据
    removeSuccessData() {
      this.loadedKeys = this.expandedKeys;
      const keys = Array.from(new Set(this.loadedKeys));
      this.reRenderTree(keys);
    },
    // 获取收藏树数据
    fetchTree() {
      let params = {};
      this.$api.home.getFolder(params).then((res) => {
        if (res.code === 200) {
          let list = res.data.map((item, index) => {
            console.log('item, index', item, index);
            item.isLeaf = false;
            item.isShow = false;
            // item.key = item.ids;
            item.scopedSlots = { title: 'more' };
            return item;
          });
          const addKey = (arr) =>
            arr.map((item) => ({
              ...item,
              key: item.ids,
              sonCameraFavoriteFolders: item.sonCameraFavoriteFolders
                ? addKey(item.sonCameraFavoriteFolders)
                : [], // 这里要判断原数据有没有子级如果没有判断会报错
            }));

          list = addKey(list);
          this.addLevel(list);
          this.addLeafToTree(list);
          this.flatDirTree = this.treeToArray([...list]);
          // this.flatDirTree2 = this.flattenTree([...list]);
          this.homeFolderCamera = [...list];
          console.log('list11', list);
          // // 更新异步加载数据
          // if (this.loadedKeys?.length > 0 && !this.isLoadData) {
          //   resolve();
          // }
        }
      });
    },
    // 手动遍历树给节点添加level
    addLevel(treeData, level = 1) {
      treeData.forEach((node) => {
        node.levelVal = level; // 添加级数字段
        node.scopedSlots = {
          title: 'more',
        };
        if (
          node.sonCameraFavoriteFolders &&
          node.sonCameraFavoriteFolders.length > 0
        ) {
          this.addLevel(node.sonCameraFavoriteFolders, level + 1); // 递归遍历子节点
        }
      });
    },
    addLeafToTree(treeData) {
      for (let i = 0; i < treeData.length; i++) {
        if (!Array.isArray(treeData[i]['sonCameraFavoriteFolders'])) {
          let f = false;
          if (
            treeData[i]?.sonCameraFavoriteFolders.length > 0 ||
            parseInt(treeData[i]?.cameraNumber) > 0
          ) {
            f = false;
          } else {
            f = true;
          }
          Object.assign(treeData[i], {
            isLeaf: f,
          });
          // treeData[i].isLeaf = true // 给叶子节点加属性
        } else {
          this.addLeafToTree(treeData[i]['sonCameraFavoriteFolders']);
          let f = false;
          if (
            treeData[i]?.sonCameraFavoriteFolders.length > 0 ||
            parseInt(treeData[i]?.cameraNumber) > 0
          ) {
            f = false;
          } else {
            f = true;
          }
          Object.assign(treeData[i], {
            isLeaf: f,
            sonCameraFavoriteFolders: treeData[i]['sonCameraFavoriteFolders'],
          });
          // treeData[i].isLeaf = false; // 给叶子节点加属性
        }
      }
    },
    treeToArray(tree) {
      return tree.reduce((res, item) => {
        const { sonCameraFavoriteFolders, ...i } = item;
        return res.concat(
          i,
          sonCameraFavoriteFolders && sonCameraFavoriteFolders.length
            ? this.treeToArray(sonCameraFavoriteFolders)
            : []
        );
      }, []);
    },
    flattenTree(data) {
      console.log(data);
      const flattened = [];
      for (const item of data) {
        flattened.push(item);
        if (
          item.sonCameraFavoriteFolders &&
          item.sonCameraFavoriteFolders.length > 0
        ) {
          flattened.push(...this.flattenTree(item.sonCameraFavoriteFolders));
          console.log('flattened', flattened, item);
        }
      }
      return flattened;
    },
    /**
     * 树节点点击
     * @param selectedKeys
     * @param e
     */
    treeNodeSelect(selectedKeys, e) {
      //设置点击展开收缩节点
      console.log('展开收缩节点', e, selectedKeys);
      // this.onLoadCameraSelectData(e.node); //fixsxy
      return;
    },
    onLoadCameraSelectData(treeNode) {
      console.log('haha', treeNode);
      this.recordMark = treeNode;
      return new Promise((resolve) => {
        // if (treeNode.cameraList) {
        //   resolve();
        //   return;
        // }
        if (treeNode) {
          if (!this.isLoadData) {
            this.expandedKeys.push(treeNode.dataRef.folderId);
            this.loadedKeys.push(treeNode.dataRef.folderId);
          }
          let data = {
            // folder: this.isSort
            //   ? treeNode?.folderId
            //   : treeNode?.dataRef.folderId,
            folder: treeNode?.dataRef.folderId,
          };
          this.$api.home.getFolderCamera(data).then((res) => {
            if (res.code === 200) {
              this.favoriteList = res.data;
              this.loading = false;
              const list = res.data.map((item, index) => {
                item.folderName = item.cameraNames;
                item.scopedSlots = { title: 'customtitle' };
                item.cameraTotal = this.favoriteList.length;
                item.children = true;
                item.isLeaf = true;
                item.cameraIndex = index;
                return item;
              });
              // if (this.favoriteList.length) {
              //   const dirList = this.flatDirTree.filter(
              //     (dir) => dir.parentFolderId == treeNode.folderId
              //   );
              //   treeNode.dataRef.sonCameraFavoriteFolders = [
              //     ...dirList,
              //     ...list,
              //   ];
              // } else {
              const dirList = this.flatDirTree.filter(
                (dir) => dir.parentFolderId == treeNode.dataRef.folderId
              );
              console.log('dirList', dirList);
              // treeNode.dataRef.sonCameraFavoriteFolders = [...dirList, ...list];
              this.$set(treeNode.dataRef, 'sonCameraFavoriteFolders', [
                ...dirList,
                ...list,
              ]);
              // }

              resolve();
              // }, 500);
              console.log(
                '排序获取摄像机数据h',
                treeNode,
                this.favoriteList,
                this.homeFolderCamera
              );
            }
          });
        }
      });
    },
    onExpand(expandedKeys, { expanded, node }) {
      // const selectArry = [];
      // selectArry.push(node.dataRef.folderId);
      // console.log(
      //   'expanded, node',
      //   expandedKeys,
      //   { expanded, node },
      //   selectArry
      // );

      // const tempKeys = (
      //   (node.parent ? node.parent.sonCameraFavoriteFolders : this.homeFolderCamera) || []
      // ).map(({ key }) => key);
      // console.log('expanded, node', node, tempKeys, this.homeFolderCamera);
      // if (expanded) {
      //   this.expandedKeys = tempKeys.filter((temp) => temp === node.eventKey);
      // } else {
      //   this.expandedKeys = expandedKeys;
      // }
      // this.onLoadCameraSelectData(this.recordMark);
      console.log('onExpand', expandedKeys, node, expanded);
      this.onLoadCameraSelectData(node);
      if (!expanded) {
        // this.onLoadCameraSelectData(node);
      }
      if (expanded) {
        this.isSort = false;
        // this.onLoadCameraSelectData(node);
      }
      this.expandedKeys = expandedKeys;
      // this.autoExpandParent = false;
    },
    onCheck(checkedKeys, info, e, tags) {
      console.log('onCheck', checkedKeys, info, e, tags);
      this.checkedKeyAryy = checkedKeys;
      // setTimeout(() => {
      this.selectArry = checkedKeys;
      // }, 500);
    },
    onSelect(selectedKeys, info, key) {
      console.log('selectedKeys, info, key', selectedKeys, info, key);
      this.folderProId = info.node.dataRef.folderId
      setTimeout(() => {
        this.favoriteList = [];
        this.selectedKeys = selectedKeys;
        this.isSort = true;
        // if (this.isSort) {
        //   this.recordMark = info.node;
        // }
        // this.parentNode = info.node;
        // this.recordMark.sonCameraFavoriteFolders = [];
        this.recordMark = this.flatDirTree.find(
          (item) => item.folderId == info.node.dataRef.folderId
        );
        this.$emit('getFolderData', this.recordMark);
        console.log('this.recordMark', this.recordMark);
        if (this.recordMark.folderName == '我的收藏夹') {
          bus.$emit('myFolder', true);
        } else {
          bus.$emit('otherFolder');
        }
        // let data = {
        //   folder: this.recordMark.folderId,
        // };
        // this.$api.home.getFolderCamera(data).then((res) => {
        //   if (res.code === 200) {
        //     if (this.recordMark.sonCameraFavoriteFolders) {
        //       this.recordMark.sonCameraFavoriteFolders =
        //         this.recordMark.sonCameraFavoriteFolders.filter(
        //           (item) => item.isLeaf !== this.isLeafs
        //         );
        //       this.recordMark.sonCameraFavoriteFolders =
        //         this.recordMark.sonCameraFavoriteFolders.concat(res.data);
        //     }

        //     this.favoriteList = res.data.map((item, index) => {
        //       item.folderName = item.cameraNames;
        //       item.scopedSlots = { title: 'customtitle' };
        //       item.isLeaf = true;
        //       item.cameraTotal = this.favoriteList.length;
        //       item.cameraIndex = index;
        //       return item;
        //     });
        //     // 组合收藏夹和收藏夹下的摄像机
        //     // this.recordMark.sonCameraFavoriteFolders = res.data;
        //     // let abc = [];
        //     // abc = this.recursionfunc(this.homeFolderCamera, this.recordMark);
        //     // this.recordMark.sonCameraFavoriteFolders;
        //     console.log(
        //       '组合的排序后的父节点',
        //       this.recordMark.sonCameraFavoriteFolders,
        //       this.recordMark,
        //       this.homeFolderCamera
        //       // this.favoriteList,
        //       // res.data
        //     );
        //     // 根据收藏夹ID查询排序之后的父节点并替换
        //     const index = this.homeFolderCamera.findIndex(
        //       (item) => item.folderId === this.recordMark.folderId
        //     );
        //     if (index !== -1) {
        //       this.homeFolderCamera.splice(index, 1, this.recordMark);
        //     }
        //   }
        // });
      }, 1000);
    },
    recursionfunc(data, updata) {
      console.log('data, updata', data, updata);
      for (let i = 0, len = data.length; i < len; i++) {
        if (data[i].sonCameraFavoriteFolders) {
          this.recursionfunc(data[i].sonCameraFavoriteFolders, updata);
        }
        if (data[i].folderId === updata.folderId) {
          data[i] = updata;
        }
      }
      return data;
    },
    onExceptChange(checkedKeys) {
      // 使用checkedKeys更新数据状态
      this.checkedKeys = checkedKeys;
    },
    onCheckChange(e) {
      console.log('item, e', e);
    },
    closeExpand() {
      this.expandedKeys = ['0'];
    },
    btn() {
      let t = false;
      this.onCheckChange(t);
    },
    clearState() {
      bus.$emit('clearStates');
      this.selectedKeys = [];
    },
  },
};
</script>

<style lang="less" scoped>
.favorite-tree-view {
  height: 100%;
  display: flex;
  color: #fff;
  overflow-x: hidden;
  .tree-box-folder {
    text-align: left;
    margin-left: 10px;
    // margin-right: 5px;
    // padding-right: 0.5rem;
    padding-bottom: 15px;
    width: 100%;
    flex: 1;
    // overflow-y: auto;
    .select-icon {
      width: 100%;
      height: 100%;
      background: url('~@/assets/resource/collect-folder.png') no-repeat;
      background-size: 100% 100%;
    }
    .folder-img {
      display: block;
      width: 16px;
      height: 12px;
      margin: 0 4px 0 -8px;
      background: url('~@/assets/resource/collect-folder.png') no-repeat;
      background-size: 100% 100%;
    }

    /deep/.ant-tree-treenode-switcher-close {
      .ant-tree-checkbox {
        display: none;
      }
    }
    .ant-tree-checkbox-inner {
      // display: none;
    }
    // /deep/.ant-tree-treenode-switcher-close::before {
    //   display: block;
    // }
    // /deep/.ant-tree-child-tree-open {
    //   li::before {
    //     display: none;
    //   }
    // }
  }
  .tree-modality {
    /deep/.ant-tree-title {
      display: flex;
    }
    /deep/.tree-title {
      width: 100% !important;
      color: #fff;
    }
    /deep/.ant-tree-switcher {
      background: none;
      // color: #15d8dd;
    }
    /deep/.ant-tree-treenode-selected {
      background-color: #1890ff33; /* 更改背景色 */
    }
  }
}
.folder-list {
  width: 100%;
  .title-btn {
    position: absolute;
    right: 30px;
  }
}
.folder {
  width: 100%;
  // display: flex;
  height: 28px;
  align-items: center;

  .name {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    text-align: left;
    color: #fffefe;
    margin-left: 8px;
    flex: 1;
    height: 100%;
    width: 100% !important;
    // border: 1px solid pink;
    // .click-btn{
    //   position: absolute;
    //   left: 40%;
    // }
  }
  .action-pull-down {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    // opacity: 0; // 是否隐藏，鼠标放上去解开
    transition: 0.3s;
    .pull-down-main {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
      .ant-dropdown-link {
        display: none;
        width: 100%;
        height: 100%;
        background: url('~@/assets/favorite/icon-favorite-more.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

.folder:hover {
  // background: linear-gradient(90deg, #02caef 0, #0e2132 0);
  opacity: 0.8;
  .action-pull-down {
    justify-content: flex-end;
    .pull-down-main {
      .ant-dropdown-link {
        &.show {
          display: inline-block;
        }
      }
    }
  }
}

/deep/.collect-box {
  .ant-row {
    .ant-checkbox-wrapper {
      .ant-checkbox {
        /deep/.ant-checkbox-inner {
          background: none !important;
          padding: 10px;
        }
      }
    }
  }
}
.ant-checkbox-group {
  display: initial;
}
/deep/.ant-tree-icon-hide {
  .ant-tree-checkbox {
    display: none;
  }
  .ant-tree-child-tree {
    .ant-tree-checkbox {
      display: inline-block;
    }
  }
}
</style>
<style lang="less">
// 下拉菜单样式
.type-menu-dropdown {
  user-select: none;
  .ant-dropdown-menu {
    width: 110px;
    background: rgba(24, 98, 135, 0.8);
    border: 1px solid #025672;
    border-radius: 2px;
    box-shadow: 0px 0px 24px 0px #03647d inset;
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      font-size: 14px;
      color: #ffffff;
    }
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    font-size: 14px;
    color: #00dbff;
    background: #1a6b94;
  }
  ::-webkit-scrollbar-thumb {
    background: #218abf;
  }
}
.ant-dropdown::before {
  display: none;
}
</style>
<style scoped>
.title-con {
  border-bottom: 1px dashed rgba(40, 69, 106, 1);
}
/* .collect-com ::-webkit-scrollbar {
  display: none;
} */
.icon-project {
  background: url('~@/assets/resource/icon-project.png') no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 13px;
}
.line-througth {
  text-decoration: line-through;
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: rgb(151, 151, 151);
}
.collect-box >>> .ant-checkbox-inner {
  background: none !important;
  margin-right: 4px;
}
.online {
  background: #5cc189;
}
.offline {
  background: #a7b1be;
}

.error {
  background: #ef5844;
}
.m-top4 {
  margin-top: 2px;
}
.m-top6 {
  margin-top: 6px;
}
</style>
