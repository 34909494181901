<template>
  <div class="flex items-center p-sm">
    <a-modal
      title="摄像机在线率统计"
      width="40%"
      :visible="visible"
      @cancel="handleCancel"
      :footer="null"
    >
      <div class="head flex justify-between">
        <div class="head-tbs">
          <div class="fl-menu flex items-center">
            <span
              :class="[{ current: activeMenu === 1 }, 'pointer']"
              style="margin-right: 8px"
              @click="toggleActive(1)"
              >公路</span
            >
            <span
              :class="[{ current: activeMenu === 2 }, 'pointer']"
              @click="toggleActive(2)"
              >其他</span
            >
          </div>
        </div>
        <!-- <a-button @click="exportHandle">导出数据</a-button> -->
        <div
          class="current pointer export"
          style="margin-right: 8px"
          @click="exportHandle()"
        >
          导出数据
        </div>
      </div>
      <div class="table" v-if="dataList.length > 0">
        <div class="main-head">
          <a-row>
            <a-col class="table-thead" :span="2">排名</a-col>
            <a-col class="table-thead" :span="10">管辖单位</a-col>
            <a-col class="table-thead" :span="4">接入总数</a-col>
            <a-col class="table-thead" :span="4">在线数</a-col>
            <a-col class="table-thead" style="padding-left: 10px" :span="4"
              >在线率</a-col
            >
          </a-row>
        </div>
        <div class="main-content">
          <div
            class="full-width flex items-center justify-center loading"
            v-show="Loading"
          >
            <a-spin />
          </div>
          <a-row
            class="table-row"
            :gutter="24"
            v-for="(item, index) in dataList"
            :key="item.key"
          >
            <a-col :span="2"
              ><div class="companyTotal" style="text-align: center">
                {{ index + 1 }}
              </div></a-col
            >
            <a-tooltip
              class="item"
              effect="dark"
              :content="item.orgName"
              placement="top"
              popper-class="typeItemTips"
              color="#0f6da3"
            >
              <template #title> {{ item.name }}</template>
              <a-col :span="10"
                ><div class="companyOrg" style="padding-left: 8px">
                  {{ item.name }}
                </div></a-col
              >
            </a-tooltip>
            <a-col :span="4"
              ><div class="companyOrg">
                {{ item.total }}
              </div></a-col
            >
            <a-col :span="4"
              ><div class="companyOrg">
                {{ item.onlineCount }}
              </div></a-col
            >
            <a-col :span="4"
              ><div class="companyOrg">{{ item.onlineRate }} %</div></a-col
            >
            <div class="line" v-if="dataList"></div>
          </a-row>
        </div>
      </div>
      <div class="noData" v-else>
        <img src="~@/assets/AI/noData.png" alt="暂无数据" />
        <p>暂无数据</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { handleExportFile } from "@/tools";
export default {
  name: "detailDialog",
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      type: 1,
      dataList: [],
      activeMenu: 1,
      Loading: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getOnlineTable();
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
    getOnlineTable() {
      this.Loading = true;
      let params = {
        type: this.type,
      };
      this.$api.home.getOnlineTotal(params).then((res) => {
        if (res.code === 200) {
          this.$nextTick(() => {
            this.Loading = false;
            this.dataList = res.data;
          });
        }
      });
    },
    toggleActive(val) {
      this.activeMenu = val;
      this.type = val;
      this.getOnlineTable();
    },
    exportHandle() {
      // this.$confirm({
      //   title: "提示",
      //   content: "确定导出在线率统计?",
      //   onOk() {
      let params = {
        type: this.type,
      };
      this.$api.home.exportOrgStatistic(params).then((res) => {
        handleExportFile(res, "在线率统计表.xlsx");
      });
      // },
      // });
    },
  },
};
</script>

<style scoped>
.main-content {
  max-height: 500px;
  overflow-x: hidden;
  position: relative;
}
.head-tbs {
  margin-bottom: 20px;
}
.table-row {
  line-height: 38px;
}
.table-thead {
  margin: 7.5px 0;
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-500;
  font-weight: 500;
  text-align: LEFT;
  color: #ade6ff;
  line-height: 16px;
  letter-spacing: 0px;
  padding: 0 6px;
}
.companyOrg {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* margin: 6px 0 2px 6px; */
}
.line {
  display: block;
  width: 100%;
  height: 0px;
  border: 1px solid;
  border-image: linear-gradient(
      270deg,
      rgba(42, 196, 253, 0) 0%,
      #2ac4fd 49%,
      rgba(42, 196, 253, 0)
    )
    1 1;
}
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.4);
}
.export {
  border: 1px solid;
  height: 30px;
  line-height: 28px;
  padding: 2px 10px;
  border-image: linear-gradient(0deg, #4679bf, #143354) 1 1;
}
.noData {
  text-align: center;
}
.noData img{
  width: 150px;
  height: 100px;
  margin: 20px 0 30px 0;
}
</style>
