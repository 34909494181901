<template>
  <a-modal
    title="设置"
    :visible="visible"
    width="40%"
    :centered="true"
    :destroyOnClose="true"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="wrapper relative">
      <div class="absolute spin" v-if="loading">
        <a-spin />
      </div>
      <div class="flex items-center m-b-sm">
        <span class="m-r-xs">自动翻页:</span>
        <a-switch class="m-r-xs" v-model="pageChecked" />
        <span class="m-r-xs">轮巡间隔:</span>
        <a-input-number
          v-model="intervalValue"
          :min="5"
          :max="1200"
          :precision="0"
          class="m-r-xs"
        />
        <span class="m-r-xs">秒</span>
        <span class="red">轮巡间隔[支持5-1200秒]</span>
      </div>
      <div class="content flex-wrap flex">
        <div
          class="wd flex-s pointer text-center"
          v-for="item in screens"
          :key="item.id"
          @click="handleClick(item)"
        >
          <div
            class="wd-content flex items-center justify-center"
            :class="{ current: currrentScreen === item.mode }"
          >
            <span
              >{{ item.text }}<br /><span v-show="item.litTit != '1*1'">{{
                item.litTit
              }}</span></span
            >
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "CameraGroupSetting",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageChecked: true, // 自动翻页
      intervalValue: 10,
      currrentScreen: "single",
      loading: false,
      litTit: "1*1",
      screenNum: 1,
      screens: [
        {
          id: 0,
          text: "单分屏",
          mode: "single",
          litTit: "1*1",
          num: "1",
        },
        {
          id: 1,
          text: "4分屏",
          mode: "four",
          litTit: "2*2",
          num: "4",
        },
        {
          id: 2,
          text: "6分屏",
          mode: "six",
          litTit: "2*3",
          num: "6",
        },
        {
          id: 3,
          text: "9分屏",
          mode: "nine",
          litTit: "3*3",
          num: "9",
        },
        {
          id: 4,
          text: "12分屏",
          mode: "twelve",
          litTit: "4*3",
          num: "12",
        },
        {
          id: 5,
          text: "16分屏",
          mode: "sixteen",
          litTit: "4*4",
          num: "16",
        },
      ],
    };
  },
  computed: {},
  watch: {
    visible(nv, ov) {
      if (!nv && nv !== ov) {
        this.currrentScreen = "single";
        this.litTit = "1*1";
        this.screenNum = 1;
        this.intervalValue = 10;
      }
    },
  },
  methods: {
    setLoading(loadingFlag) {
      this.loading = loadingFlag;
    },
    handleCancel() {
      this.currrentScreen = "single";
      this.$emit("close");
    },
    handleOk() {
      if (this.intervalValue === "" || Number(this.intervalValue) < 5) {
        this.$message.error("轮巡间隔需在5-120秒之间");
        return;
      }
      // this.$emit('currentScreen', {
      //   mode: this.currrentScreen,
      //   litTit: this.litTit,
      //   interval: this.intervalValue,
      //   pageChecked: this.pageChecked,
      //   screenNum: this.screenNum
      // })
      this.$emit("currentScreen", {
        mode: this.currrentScreen,
        litTit: this.litTit,
        interval: this.intervalValue,
      });
      console.log("this.litTit", this.litTit, this.currrentScreen, this.intervalValue);
      // if (this.litTit == '1*1') {
      //   localStorage.setItem('screenNum', 1)
      // }
      /* this.currrentScreen = 'single';
      this.litTit = '1*1'; */
    },
    handleClick(item) {
      // localStorage.setItem('screenNum', item.num)
      this.currrentScreen = item.mode;
      this.litTit = item.litTit;
      this.screenNum = item.num;
      console.log("itemitemitemitem", item);
    },
  },
};
</script>

<style scoped lang="less">
.wd {
  width: 33.3%;
  .wd-content {
    margin: 5px;
    border: 1px solid #243151;
    height: 80px;
    &.current {
      background: #144061;
      border: 1px solid #146ea6;
    }
  }
}
.spin {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
