<template>
  <!-- <Header></Header> -->
  <div class="full-width full-height flex relative">
    <div class="h-i-l absolute r-index" id="left">
      <div
        class="full-height drag-bar absolute"
        @mousedown="mouseDown($event)"
      ></div>
      <home-left></home-left>
    </div>
    <div class="h-i-w flex full-height">
      <div class="h-i-m flex-one m-l-sm m-r-sm" id="main">
        <home-main v-hasPermiss="'10921013'"></home-main>
      </div>
      <div class="h-i-r" id="right">
        <home-right></home-right>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
// import Header from '@/components/Header';
import HomeLeft from "./HomeLeft";
import HomeRight from "./HomeRight";
import HomeMain from "./HomeMain";
export default {
  name: "Home",
  components: {
    // Header,
    HomeLeft,
    HomeRight,
    HomeMain,
  },
  data() {
    return {
      intervalId: null,
    };
  },
  methods: {
    mouseDown(e) {
      let startX = e.clientX;
      let oLeft = document.querySelector("#left");
      let left = oLeft.offsetWidth;
      oLeft.style.background = "#182846";
      this.isDrag = true;
      document.onmousemove = (e) => {
        if (this.isDrag) {
          let endX = e.clientX;
          let moveLen = left + (endX - startX);
          let fs = parseFloat(document.querySelector("html").style.fontSize);
          let oMain = document.querySelector("#main");
          let oRight = document.querySelector("#right");
          let maxW = oMain.offsetWidth + oRight.offsetWidth + 0.5 * fs;
          let minW = 16 * fs;
          if (moveLen <= minW) {
            moveLen = minW;
          }
          if (moveLen >= maxW) {
            moveLen = maxW;
          }
          this.setTreeListWd(moveLen);
          oLeft.style.width = moveLen + "px";
        }
      };
      document.onmouseup = (e) => {
        this.mouseUp(e), oLeft.releaseCapture && oLeft.releaseCapture();
      };
      oLeft.releaseCapture && oLeft.releaseCapture();
      return false;
    },
    mouseUp(e) {
      e.stopPropagation();
      document.onmousemove = null;
      document.onmouseup = null;
      this.isDrag = false;
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        location.reload(); //加载数据函数
      }, 300000);
    },
    // 停止定时器
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    ...mapActions(["logout"]),
    ...mapMutations(["setTreeListWd"]),
    /*  logoutHandle() {
      this.logout();
    },
    openApi() {}, */
  },
  created() {
    /*  this.dataRefreh(); */
  },
  destroyed() {
    // 在页面销毁后，清除计时器
    /* this.clear(); */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-wrap {
  background: #0f182d url("../../../assets/home/home-bg.jpg") bottom center
    no-repeat;
  width: 100%;
  height: 100%;
}
.home-inner {
  border: 1px solid #4a6080;
  border-top: none;
  height: calc(100% - 67px);
  padding: 0 15px 20px;
}
.drag-bar {
  width: 5px;
  background: #fff;
  right: -10px;
  top: 0;
  cursor: e-resize;
  z-index: 999;
  opacity: 0;
}
.r-index {
  z-index: 999;
}
.h-i-w {
  padding-left: 320px;
  width: 100%;
}
</style>
<style>
.home-inner .h-i-l,
.home-inner .h-i-r {
  /* width: 32rem; */
  width: 320px;
  height: 100%;
}
</style>
