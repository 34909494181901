var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{staticClass:"favorite-play-modal",attrs:{"title":_vm.title,"visible":_vm.visible,"width":"25%","centered":true,"destroyOnClose":true,"confirm-loading":_vm.confirmLoading},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('div',{staticClass:"flex form-data"},[_c('div',[(_vm.folderInfo.folderName && !_vm.edit)?_c('div',[_c('span',{staticClass:"form-title"},[_vm._v("上级收藏夹:")]),_c('span',[_vm._v(_vm._s(_vm.folderInfo.folderName))])]):_c('div',[_c('span',{staticClass:"form-title"},[_vm._v("上级收藏夹:")]),_c('a-cascader',{staticClass:"favorite-box",staticStyle:{"width":"220px","color":"#fff"},attrs:{"options":_vm.favoriteList,"change-on-select":"","expand-trigger":"hover","placeholder":"根层级收藏夹","fieldNames":{
              label: 'folderName',
              value: 'folderId',
              children: 'sonCameraFavoriteFolders',
            }},on:{"change":_vm.selectFavorite},model:{value:(_vm.superiorFolder),callback:function ($$v) {_vm.superiorFolder=$$v},expression:"superiorFolder"}})],1)])]),_c('div',{staticClass:"flex form-data box"},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"form-title"},[_vm._v("收藏夹名称:")]),(_vm.edit)?_c('a-input',{staticStyle:{"width":"220px"},attrs:{"placeholder":"请输入收藏夹名称"},on:{"change":_vm.handleChange},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_vm._e()],1),_c('div',{staticClass:"clear"}),(!_vm.edit)?_c('div',{staticClass:"flex content-box"},[_c('a-form',{attrs:{"label-col":{ style: { width: '10px' } },"autocomplete":"off","form":_vm.form}},_vm._l((_vm.favoriteNameList),function(k,index){return _c('a-form-item',{key:index,attrs:{"required":false}},[_c('div',{staticClass:"favorite-name-box"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  ("names[" + index + "]"),
                  {
                    validateTrigger: ['change', 'blur'],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: '收藏夹名称不可为空',
                      },
                      {
                        max: 50,
                        message: '收藏夹名称不可超过50个字符',
                      } ],
                  } ]),expression:"[\n                  `names[${index}]`,\n                  {\n                    validateTrigger: ['change', 'blur'],\n                    rules: [\n                      {\n                        required: true,\n                        whitespace: true,\n                        message: '收藏夹名称不可为空',\n                      },\n                      {\n                        max: 50,\n                        message: '收藏夹名称不可超过50个字符',\n                      },\n                    ],\n                  },\n                ]"}],class:("favorite" + index),staticStyle:{"width":"220px","margin-right":"16px"},attrs:{"placeholder":"请输入收藏夹名称"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addFavoriteName(index, true)}}}),_c('div',{staticClass:"favorite-name-control"},[(_vm.favoriteNameList.length > 1 && index !== 0)?_c('div',{staticClass:"control control-minus",on:{"click":function($event){return _vm.minusFavoriteName(index)}}},[_c('img',{attrs:{"src":require("@/assets/favorite/favorite-minus.png"),"alt":"减"}})]):_vm._e(),(
                    _vm.favoriteNameList.length - 1 == index &&
                    index !== _vm.favoriteNameNum - 1
                  )?_c('div',{staticClass:"control control-plus-sign",attrs:{"title":"批量新增收藏夹"},on:{"click":function($event){return _vm.addFavoriteName(index)}}},[_c('img',{attrs:{"src":require("@/assets/favorite/favorite-plus-sign.png"),"alt":"加"}})]):_vm._e()])],1)])}),1)],1):_vm._e()]),(_vm.inputRules)?_c('p',{staticStyle:{"color":"red","padding-left":"19%","margin-top":"-13px"}},[_vm._v(" 收藏夹名称不可为空 ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }