var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vuedraggable-main"},[_c('div',{staticClass:"itxst",attrs:{"id":"itxst"}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"itxst-list flex items-center m-b-sm p-w-sm",attrs:{"data-id":item.favoriteId,"title":item.cameraNames}},[_c('span',{class:[
          'icon-style  m-r-xs flex-s',
          {
            online: item.cameraStatus === 1,
            offline: item.cameraStatus === 0,
            error: item.cameraStatus === 2,
            yunOffline: item.cameraStatus === 0 && _vm.$g.treeDefinate,
          } ]}),_c('span',{staticClass:"flex-one move ellipsis",class:{
          'text-lightgray line-througth': item.deleteStatus === 1,
        },staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.cameraNames))])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }