<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      width="25%"
      class="favorite-play-modal"
      :centered="true"
      :destroyOnClose="true"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <div class="flex form-data">
        <div>
          <div v-if="folderInfo.folderName && !edit">
            <span class="form-title">上级收藏夹:</span>
            <span>{{ folderInfo.folderName }}</span>
          </div>
          <div v-else>
            <span class="form-title">上级收藏夹:</span>
            <a-cascader
              :options="favoriteList"
              v-model="superiorFolder"
              change-on-select
              @change="selectFavorite"
              expand-trigger="hover"
              class="favorite-box"
              style="width: 220px; color: #fff"
              placeholder="根层级收藏夹"
              :fieldNames="{
                label: 'folderName',
                value: 'folderId',
                children: 'sonCameraFavoriteFolders',
              }"
            />
          </div>
        </div>
      </div>
      <div class="flex form-data box">
        <div class="flex">
          <span class="form-title">收藏夹名称:</span>
          <a-input
            v-if="edit"
            style="width: 220px"
            placeholder="请输入收藏夹名称"
            v-model="text"
            @change="handleChange"
          />
        </div>
        <div class="clear"></div>
        <!-- <span
          class="text-red block m-t-xs"
          style="color: #f5222d"
          v-show="validError && edit"
          >输入的名称不符合格式。</span
        > -->
        <div class="flex content-box" v-if="!edit">
          <a-form
            :label-col="{ style: { width: '10px' } }"
            autocomplete="off"
            :form="form"
          >
            <a-form-item
              v-for="(k, index) in favoriteNameList"
              :key="index"
              :required="false"
            >
              <div class="favorite-name-box">
                <a-input
                  :class="`favorite${index}`"
                  v-decorator="[
                    `names[${index}]`,
                    {
                      validateTrigger: ['change', 'blur'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '收藏夹名称不可为空',
                        },
                        {
                          max: 50,
                          message: '收藏夹名称不可超过50个字符',
                        },
                      ],
                    },
                  ]"
                  placeholder="请输入收藏夹名称"
                  style="width: 220px; margin-right: 16px"
                  @keyup.enter="addFavoriteName(index, true)"
                />
                <div class="favorite-name-control">
                  <div
                    class="control control-minus"
                    v-if="favoriteNameList.length > 1 && index !== 0"
                    @click="minusFavoriteName(index)"
                  >
                    <img src="@/assets/favorite/favorite-minus.png" alt="减" />
                  </div>
                  <div
                    class="control control-plus-sign"
                    v-if="
                      favoriteNameList.length - 1 == index &&
                      index !== favoriteNameNum - 1
                    "
                    @click="addFavoriteName(index)"
                    title="批量新增收藏夹"
                  >
                    <img
                      src="@/assets/favorite/favorite-plus-sign.png"
                      alt="加"
                    />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <p
        v-if="inputRules"
        style="color: red; padding-left: 19%; margin-top: -13px"
      >
        收藏夹名称不可为空
      </p>
    </a-modal>
  </div>
</template>

<script>
// let validatePass = (rule, value, callback) => {
//   if (value === '') {
//     callback(new Error('请输入密码'));
//   } else {
//     callback();
//   }
// };
import { mapActions } from 'vuex';

export default {
  name: 'FavoritToggle',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isSon: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    folderId: {
      type: String,
      default: '',
    },
    folderName: {
      type: String,
      default: '',
    },
    parentData: {
      type: String,
      default: '',
    },
    folderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      confirmLoading: false,
      text: '',
      inputRules: false,
      superiorFolder: [],
      favoriteNameNum: 30, // 收藏夹个数
      favoriteList: [],
      isSelect: false,
      favoriteNameList: [
        {
          name: '',
        },
      ],
    };
  },
  created() {},
  computed: {
    title() {
      return `${this.edit ? '修改' : '新增'}收藏夹`;
    },
    validError() {
      let reg = /\s/g;
      return reg.test(this.text) ||
        this.text.length > 50 ||
        this.text.length <= 0
        ? true
        : false;
    },
  },
  watch: {
    visible(nv, ov) {
      console.log('haha', nv, ov);
      if (nv && nv !== ov) {
        if (this.edit) {
          this.fetchList();
          this.text = this.folderName;
          if (this.folderInfo.levelVal == 1) {
            this.favoriteList = [];
            this.superiorFolder = [];
          } else {
            setTimeout(() => {
              this.superiorFolder = this.getTreeIds(
                this.favoriteList,
                this.folderId
              );
            }, 1000);
          }
        }
      }
    },
  },
  mounted() {
    this.fetchList();
    // if (this.folderInfo.levelVal == 1) {
    //   this.favoriteList = [];
    // } else {
    //   this.fetchList();
    //   setTimeout(() => {
    //     this.superiorFolder = this.getTreeIds(this.favoriteList, this.folderId);
    //   }, 1000);
    // }
  },
  methods: {
    // 获取上级文件夹
    fetchList() {
      let params = { is: true };
      this.$api.home.selectFolder(params).then((res) => {
        if (res.code === 200) {
          this.favoriteList = res.data;
          console.log('获取下拉数据', this.favoriteList);
        }
      });
    },
    selectFavorite(value, options) {
      console.log('value, options', value, options);
      this.isSelect = true;
    },
    handleChange(e) {
      if (!e.target.value) {
        this.inputRules = true;
      } else {
        this.inputRules = false;
      }
    },
    handleCancel() {
      this.$emit('close');
      if (!this.edit) {
        this.favoriteNameList = [
          {
            name: '',
          },
        ];
      }
      this.confirmLoading = false;
      this.superiorFolder = [];
    },
    handleOk() {
      if (this.edit) {
        if (this.validError) {
          /* this.$message.error('收藏文件夹名需要支持20个字符，非空格输入'); */
          return;
        }
        let data = {
          folderName: this.text,
          // parentFolderId: '0',
          originalParentFolderId: this.folderInfo.parentFolderId,
          folderId: this.folderId,
          // parentFolderId: this.isSon ? this.folderId : this.superiorFolder,
          parentFolderId: this.isSelect
            ? this.superiorFolder[this.superiorFolder.length - 1]
            : this.folderInfo.parentFolderId,
        };
        if (this.edit) {
          data.folderId = this.folderId;
          data.folderNameBefore = this.folderName;
        }
        this.confirmLoading = true;
        this.createFolderAction(data).then(() => {
          this.confirmLoading = false;
          this.text = '';
          this.$emit('sure');
          this.fetchList();
          this.$emit('clear');
          this.inputRules = false;
        });
      } else {
        this.form.validateFields((err, values) => {
          if (!err) {
            console.log('errvalues', err, values, this.favoriteNameList);
            this.confirmLoading = true;
            let nameArr = values.names;
            let data = [];
            nameArr.map((it, i) => {
              this.favoriteNameList[i].name = it;
              if (
                this.add &&
                !this.isSelect &&
                !this.folderInfo.folderName
              ) {
                data[i] = {
                  folderName: it,
                  parentFolderId: 0,
                };
              } else {
                data[i] = {
                  folderName: it,
                  parentFolderId: this.folderInfo.folderName
                    ? this.folderInfo.folderId
                    : this.superiorFolder[this.superiorFolder.length - 1],
                };
              }
            });
            this.$api.home.addFavoriteBatchSave(data).then((res) => {
              if (res.data.code == 200) {
                this.confirmLoading = false;
                if (!this.edit) {
                  this.favoriteNameList = [
                    {
                      name: '',
                    },
                  ];
                  this.superiorFolder = [];
                }
                this.$emit('sure');
                this.$emit('success');
                this.fetchList();
                this.inputRules = false;
              } else {
                this.confirmLoading = false;
                this.$message.error(res.data?.message);
                // this.$message.error('收藏夹已超过最大层级');
              }
            });
          }
        });
      }
      this.fetchList();
      this.isSelect = false;
    },

    // 通过子节点ID查找所有关联的父节点
    getTreeIds(tree, nodeId, config) {
      const { children = 'sonCameraFavoriteFolders', id = 'folderId' } =
        config || {};
      const toFlatArray = (tree, parentId) => {
        return tree.reduce((t, _) => {
          const child = _[children];
          return [
            ...t,
            parentId ? { ..._, parentId } : _,
            ...(child && child.length ? toFlatArray(child, _[id]) : []),
          ];
        }, []);
      };
      const getIds = (flatArray) => {
        let ids = [nodeId];
        let child = flatArray.find((_) => _[id] === nodeId);
        while (child && child.parentId) {
          ids = [child.parentId, ...ids];
          child = flatArray.find((_) => _[id] === child.parentId);
        }
        return ids;
      };
      return getIds(toFlatArray(tree));
    },
    // 收藏夹名称输入框减少
    minusFavoriteName(index) {
      const fruits = this.favoriteNameList;
      fruits.splice(index, 1);
      this.favoriteNameList = fruits;
    },
    // 收藏夹名称输入框增加
    addFavoriteName(index, isKeyboard) {
      const fruits = this.favoriteNameList;
      if (fruits.length >= this.favoriteNameNum) {
        return false;
      }
      fruits.splice(index + 1, 0, { name: '' });
      this.favoriteNameList = fruits;
      // 聚焦
      if (isKeyboard) {
        this.autoFocus(index + 1);
      }
      // 滚动条滚动到底部
      this.scrollToBottom();
    },
    // 输入框聚焦
    autoFocus(id) {
      this.$nextTick(() => {
        const child = document.querySelector(`.favorite${id}`);
        child && child.focus();
      });
    },
    // 滚动条滚动到底部
    scrollToBottom() {
      const container = document
        .querySelector('.favorite-play-modal')
        .querySelector('.ant-modal-content')
        .querySelector('.ant-modal-body');
      this.$nextTick(() => {
        container && (container.scrollTop = container.scrollHeight);
      });
      container && (container.scrollTop = container.scrollHeight + 150);
    },
    ...mapActions(['createFolderAction']),
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
  },
};
</script>

<style  lang="less" scoped>
.content-box {
  // margin: 51px 80px 51px 37px;
  margin: 0 80px 0 0;
  .favorite-name-box {
    position: relative;
    width: 100%;
    .favorite-name-control {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0 8px;
      .control {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .control-minus {
        margin-right: 6px;
      }
    }
  }
}
.clear {
  clear: both;
}
</style>
<style  lang="less" scoped>
.favorite-play-modal {
  .ant-modal {
    .ant-modal-body {
      max-height: 350px;
      overflow-y: auto;
    }
  }
  .form-title {
    line-height: 32px;
    // width: 92px;
    margin-right: 4px;
  }
  .form-data {
    padding-bottom: 20px;
  }
  .box {
    max-height: 300px;
    overflow: auto;
  }
}
.clear {
  clear: both;
}
/deep/.ant-cascader-picker-clear {
  color: #fff;
}
/deep/.ant-cascader-menu-item-expand-icon {
  color: #fff !important;
}
/deep/.ant-cascader-menu-item-expand {
  color: #fff !important;
}
/deep/.anticon {
  color: #fff !important;
}
</style>
