var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cameras-cancel-box"},[_c('div',{staticClass:"cameras-cancel-back"},[_c('p',{staticStyle:{"color":"#fff","padding":"0 20px"}},[_vm._v("批量取消收藏")]),_c('a-button',{staticClass:"but",on:{"click":_vm.onCancel}},[_vm._v("返回")])],1),_c('div',{staticClass:"cameras-cancel-top"},[_c('div',{staticClass:"cameras-cancel-top-title"},[_vm._m(0),_c('div',{staticClass:"cameras-data"},[_vm._v(" "+_vm._s(_vm.favoriteData.folderName)+"("+_vm._s(_vm.duplicates.length)+"/"+_vm._s(_vm.favoriteList.length)+") ")])]),_c('a-spin',{staticClass:"ma-spin",attrs:{"spinning":_vm.loading}}),(_vm.favoriteList.length > 0)?_c('div',{staticClass:"cameras-cancel-top-main"},[_c('div',{staticClass:"vuedraggable-main"},[_c('div',{staticClass:"itxst",attrs:{"id":"itxst"}},[_c('a-checkbox-group',{staticClass:"flex-one",on:{"change":_vm.onCheckChange},model:{value:(_vm.checkedValues),callback:function ($$v) {_vm.checkedValues=$$v},expression:"checkedValues"}},[_c('a-row',_vm._l((_vm.favoriteList),function(item,index){return _c('a-col',{key:index,staticClass:"itxst-list flex items-center m-b-sm p-w-sm",attrs:{"data-id":item.favoriteId,"title":item.cameraNames}},[_c('a-checkbox',{attrs:{"value":item.favoriteId}},[_c('span',{staticClass:"camera-state",class:[
                      'icon-style  m-r-xs flex-s',
                      {
                        online: item.cameraStatus === 1,
                        error: item.cameraStatus === 0,
                        offline: item.cameraStatus === 2,
                        yunOffline:
                          item.cameraStatus === 0 && _vm.$g.treeDefinate,
                      } ]}),_c('span',{staticClass:"flex-one move ellipsis",class:{
                      'text-lightgray line-througth': item.deleteStatus === 1,
                    },staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.cameraNames))])])],1)}),1)],1)],1)])]):_vm._e()],1),_c('div',{staticClass:"cameras-cancel-bot-but"},[_c('a-button',{staticClass:"ml16 but",staticStyle:{"border":"1px solid #ffffff","border-radius":"4px","background":"transparent"},on:{"click":_vm.allCancel}},[_vm._v("全部取消")]),_c('a-button',{staticClass:"ml16 but",staticStyle:{"background":"#079fda","border-radius":"4px"},attrs:{"type":"primary"},on:{"click":_vm.onSave}},[_vm._v("批量取消")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"folder"},[_c('img',{attrs:{"src":require("@/assets/resource/collect-folder.png")}})])}]

export { render, staticRenderFns }