<template>
  <div class="cameras-cancel-box">
    <!-- 排序头部 -->
    <div class="cameras-cancel-back">
      <p style="color: #fff; padding: 0 20px">批量取消收藏</p>
      <a-button class="but" @click="onCancel">返回</a-button>
    </div>
    <!-- 排序详情 -->
    <div class="cameras-cancel-top">
      <div class="cameras-cancel-top-title">
        <div class="folder">
          <img src="@/assets/resource/collect-folder.png" />
        </div>
        <div class="cameras-data">
          {{ favoriteData.folderName }}({{ duplicates.length }}/{{
            favoriteList.length
          }})
        </div>
      </div>
      <!-- 摄像机列表 -->
      <a-spin class="ma-spin" :spinning="loading"></a-spin>
      <div class="cameras-cancel-top-main" v-if="favoriteList.length > 0">
        <!-- <draggable
          :list="favoriteList"
          animation="300"
          :forceFallback="true"
          @start="onStart"
          @end="onEnd"
          @updatecancel="updatecancel"
        ></draggable> -->
        <div class="vuedraggable-main">
          <div id="itxst" class="itxst">
            <a-checkbox-group
              v-model="checkedValues"
              @change="onCheckChange"
              class="flex-one"
            >
              <a-row>
                <a-col
                  class="itxst-list flex items-center m-b-sm p-w-sm"
                  v-for="(item, index) in favoriteList"
                  :key="index"
                  :data-id="item.favoriteId"
                  :title="item.cameraNames"
                >
                  <a-checkbox :value="item.favoriteId">
                    <span
                      class="camera-state"
                      :class="[
                        'icon-style  m-r-xs flex-s',
                        {
                          online: item.cameraStatus === 1,
                          error: item.cameraStatus === 0,
                          offline: item.cameraStatus === 2,
                          yunOffline:
                            item.cameraStatus === 0 && $g.treeDefinate,
                        },
                      ]"
                    ></span>
                    <span
                      class="flex-one move ellipsis"
                      :class="{
                        'text-lightgray line-througth': item.deleteStatus === 1,
                      }"
                      style="white-space: nowrap"
                      >{{ item.cameraNames }}</span
                    >
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
            <!-- <div
              class="itxst-list flex items-center m-b-sm p-w-sm"
              v-for="(item, index) in favoriteList"
              :key="index"
              :data-id="item.favoriteId"
              :title="item.cameraNames"
            >
              <span
                :class="[
                  'icon-style  m-r-xs flex-s',
                  {
                    online: item.cameraStatus === 1,
                    offline: item.cameraStatus === 0,
                    error: item.cameraStatus === 2,
                    yunOffline: item.cameraStatus === 0 && $g.treeDefinate,
                  },
                ]"
              ></span>
              <span
                class="flex-one move ellipsis"
                :class="{
                  'text-lightgray line-througth': item.deleteStatus === 1,
                }"
                style="white-space: nowrap"
                >{{ item.cameraNames }}</span
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="cameras-cancel-bot-but">
      <a-button
        style="
          border: 1px solid #ffffff;
          border-radius: 4px;
          background: transparent;
        "
        class="ml16 but"
        @click="allCancel"
        >全部取消</a-button
      >
      <a-button
        style="background: #079fda; border-radius: 4px"
        class="ml16 but"
        type="primary"
        @click="onSave"
        >批量取消</a-button
      >
    </div>
  </div>
</template>

<script>
import bus from '@/components/js/eventBus';
export default {
  name: 'favoriteCamerasCancel',
  props: {
    dataList: {
      type: Object,
      default: null,
    },
  },
  components: {
    // draggable: vuedraggable,
  },
  data() {
    return {
      ishint: true, // 是否为组件销毁后需要提示
      favoriteData: this.dataList, // 需要排序的文件夹
      loading: true, // // 数据加载中
      favoriteList: [], // 摄像机数据
      favoritecancel: [], // 排序值
      checkedValues: [],
      isCancel: false,
      duplicates: [],
    };
  },

  mounted() {
    console.log('newArrq', this.dataList);
    const uniqueData = this.favoriteList.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.onlineStatus === item.onlineStatus)
    );
    console.log('newArr', uniqueData, this.favoriteList);
  },
  computed: {
    uniqueItems() {
      const uniqueData = this.favoriteList.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.onlineStatus === item.onlineStatus)
      );
      return uniqueData;
    },
  },
  destroyed() {
    if (this.ishint) {
      this.$message.warning(
        '摄像机批量取消,切换页面后调整的排序将丢失恢复初始值。'
      );
    }
  },
  methods: {
    // 返回
    onCancel() {
      this.ishint = false;
      this.$emit('cancel');
      if (this.isCancel) {
        bus.$emit('refreshFolderList');
        bus.$emit('closeTree');
        this.isCancel = false;
      }
    },
    // 全部取消
    allCancel() {
      this.$confirm({
        title: '提示',
        content: '确认取消收藏所有的摄像机吗?',
        onOk: () => {
          let data = {
            folder: this.favoriteData?.folderId,
          };
          this.$api.home.allCancel(data).then((res) => {
            if (res.code === 200) {
              this.$message.success('全部取消成功！');
              this.isCancel = true;
              this.fetchList();
            } else {
              this.$message.error('全部取消失败！');
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 批量取消
    onSave() {
      if (!(this.favoriteList.length > 0)) {
        return false;
      }
      this.$confirm({
        title: '提示',
        content: '确认取消收藏所选摄像机吗?',
        onOk: () => {
          this.$api.home.batchFavorite(this.favoritecancel).then((res) => {
            if (res.code == 200) {
              this.$message.success('批量取消成功');
              this.isCancel = true;
              // this.$emit('updateFavorite');
              this.fetchList();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 开始拖拽
    onStart() {},
    // 拖拽结束
    onEnd() {},
    // 更新拖拽后的值
    updatecancel(data) {
      this.favoritecancel = data;
    },
    // 获取摄像机数据
    fetchList() {
      this.loading = true;
      this.favoriteList = [];
      let params = { folder: this.favoriteData?.folderId };
      this.$api.home.getFolderCamera(params).then((res) => {
        if (res.code === 200) {
          this.favoriteList = res.data;
          this.loading = false;
          // this.favoriteList.push({
          //   favoriteId: '10832',
          //   cameraId: '1364885907269816322',
          //   folderId: '199183256',
          //   cameraNum: 'E50BEB41-5EDD-7AA5-73F6-4C1FC455B65C',
          //   cameraStatus: 1,
          //   latitude: '42.426576066363864',
          //   longitude: '122.70890083500356',
          //   cameraNames:
          //     'G25辽宁省阜新市阜新蒙古族自治县K305+500彰武服务区出口01↑',
          //   deleteStatus: 0,
          //   organizationId: '9901050015002',
          //   transcodingId: '1319901999185137664',
          //   sort: 0,
          //   status: 1,
          //   gmtCreateUid: '10000000052',
          //   gmtCreate: '2024-07-10 19:02:13',
          //   gmtModifiedUid: null,
          //   gmtModified: null,
          //   ids: '1991832561364885907269816322',
          //   classify: 6,
          //   onlineStatus: 0,
          // });
          // 在线数
          const counts = {};
          this.favoriteList.forEach((item) => {
            counts[item.onlineStatus] = (counts[item.onlineStatus] || 0) + 1;
          });

          this.duplicates = this.favoriteList.filter(
            (item) => counts[item.onlineStatus] > 1
          );
          console.log(
            '排序获取摄像机数据h',
            this.duplicates,
            this.favoriteList
          );
        }
      });
    },
    onCheckChange(checkedValues) {
      console.log('checkedValues', checkedValues);
      this.favoritecancel = checkedValues;
    },
  },
  watch: {
    dataList: {
      immediate: true,
      deep: true,
      handler() {
        this.favoriteData = this.dataList;
        this.fetchList();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.cameras-cancel-box {
  width: 100%;
  height: 100%;
  padding: 16px 0px;
  // border: 1px solid red;
  //   background: pink;
  .cameras-cancel-back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 20px);
  }
  .cameras-cancel-top {
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: calc(100% - 104px - 20px);
    // background: blue;
    .cameras-cancel-top-title {
      width: 100%;
      height: 24px;
      padding: 0 10px 0 30px;
      //   border: 1px solid red;
      display: flex;
      align-items: center;
      .folder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17px;
        height: 13px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .cameras-data {
        width: calc(100% - 17px - 10px);
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .cameras-cancel-top-main {
      width: 100%;
      height: calc(100% - 24px);
      //   margin-top: 15px;
      padding: 6px 0 0 40px;
      //   border: 1px solid orange;
      overflow: hidden;
      overflow-y: auto;
      /deep/.ant-checkbox-inner {
        border: 0.05rem solid #d9d9d9;
        background: none;
      }
    }
    .ma-spin {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .cameras-cancel-bot-but {
    display: flex;
    justify-content: end;
    margin-right: 4%;
  }
}
.ml16 {
  margin-left: 16px !important;
}
.but {
  &.ant-btn:focus,
  &.ant-btn:hover {
    color: #ffffff;
  }
}
.itxst {
  .camera-state {
    display: block;
    float: left;
    margin-top: 6px;
  }
  .ant-checkbox-wrapper {
    display: flex;
  }
  /deep/.ant-checkbox {
    padding-top: 2px;
  }
}
</style>
