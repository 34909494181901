<template>
  <div class="collect-com full-height">
    <!-- 摄像机排序 -->
    <div class="favorite-sort" v-if="favoriteSortShow">
      <favorite-cameras-sort
        :dataList="favoriteDataList"
        @cancel="sortCancel"
        @updateFavorite="updateFavorite"
      ></favorite-cameras-sort>
    </div>
    <div class="favorite-sort" v-if="favoriteCancelShow">
      <favorite-cameras-cancel
        :dataList="favoriteDataList"
        @cancel="sortCancel"
        @updateFavorite="updateFavorite"
      ></favorite-cameras-cancel>
    </div>
    <div
      class="p-sm full-height"
      v-show="!favoriteSortShow || !favoriteCancelShow"
    >
      <div class="flex items-center justify-between m-b-sm title-con p-h-sm">
        <a-tooltip placement="topLeft" class="pointer">
          <template slot="title">
            <span>新增收藏夹</span>
          </template>
          <img
            src="../assets/collect/add.png"
            width="20"
            class="m-r-xs"
            v-if="!addShow"
          />
          <img
            src="../assets/collect/add-active.png"
            width="20"
            class="m-r-xs"
            @click="addFavoritHandle"
            v-else
          />
        </a-tooltip>
        <a-tooltip placement="topLeft" class="pointer">
          <template slot="title">
            <span>修改收藏夹</span>
          </template>
          <img
            src="../assets/collect/edit.png"
            width="20"
            class="m-r-xs"
            v-if="clickMyCollect"
          />
          <img
            src="../assets/collect/edit-active.png"
            width="20"
            class="m-r-xs"
            @click="editFavorit"
            v-else
          />
        </a-tooltip>
        <a-tooltip placement="topLeft" class="pointer">
          <template slot="title">
            <span>删除收藏夹</span>
          </template>
          <img
            src="../assets/collect/del.png"
            width="20"
            class="m-r-xs"
            v-if="clickMyCollect || myCollect"
          />
          <img
            src="../assets/collect/del-active.png"
            width="20"
            class="m-r-xs"
            @click="deleteFolder"
            v-else
          />
        </a-tooltip>
        <a-tooltip placement="topLeft" class="pointer">
          <template slot="title">
            <span>摄像机排序</span>
          </template>
          <img
            src="../assets/collect/sort.png"
            width="20"
            class="m-r-xs"
            v-if="myCollect"
          />
          <img
            src="../assets/collect/sort-active.png"
            width="20"
            class="m-r-xs"
            @click="camerasSort"
            v-else
          />
        </a-tooltip>
        <a-tooltip placement="topLeft" class="pointer" v-if="false">
          <template slot="title">
            <span>批量取消收藏</span>
          </template>
          <img
            src="../assets/collect/cancel.png"
            width="20"
            class="m-r-xs"
            v-if="myCollect"
          />
          <img
            src="../assets/collect/cancel-active.png"
            width="20"
            class="m-r-xs"
            @click="cancel"
            v-else
          />
        </a-tooltip>
        <a-tooltip placement="topLeft" class="pointer">
          <template slot="title">
            <span>列表播放</span>
          </template>
          <img
            src="../assets/collect/play.png"
            width="20"
            class="m-r-xs"
            v-if="myCollect"
          />
          <img
            src="../assets/collect/play-active.png"
            width="20"
            class="m-r-xs"
            @click="projector()"
            v-else
          />
        </a-tooltip>
        <!-- <span
          class="flex flex-end text-lightblue items-center pointer"
          v-show="ope"
          @click="newTurnHandle"
        >
          <a-icon type="plus-circle" class="m-r-xs" />收藏轮巡
        </span> -->
      </div>
      <!-- 树结构 -->
      <div class="folder-wrapper-tree">
        <favorite-tree
          ref="folderTree"
          :ope="ope"
          @favoriteCamerasSortOpen="favoriteCamerasSortOpen"
          @favoriteCamerasCancelOpen="favoriteCamerasCancelOpen"
          @getFolderData="getFolderData"
        ></favorite-tree>
      </div>
    </div>

    <new-turn
      :visible="turnVisible"
      selectAllMode="collect"
      @closeDialog="closeDialogHandle"
    />

    <favorit-toggle
      :visible="favoritVisible"
      :add="add"
      :edit="isEdit"
      :folderId="folderId"
      :folderName="folderName"
      :folderInfo="folderData"
      @close="closeFavorit"
      @sure="sureFavorit"
      @success="success"
    />
    <!--收藏夹投屏-->
    <camera-group-setting
      ref="cameraGroupSetting"
      :visible="visibleGroupSetting"
      @close="closeGroupSettingHandle"
      @currentScreen="currentScreenHandle"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import newTurn from '@/components/TurnDialog';
// import FavoritToggle from '@/components/FavoritToggle';
import FavoritToggle from '@/components/addFavoritToggle';
// import CameraGroupSetting from './CameraGroupSetting';
import CameraGroupSetting from './FavoriteCameraGroupSetting.vue';
import favoriteTree from '@/components/favorite/index.vue';
import favoriteCamerasSort from '@/components/favorite-cameras-sort/index.vue';
import favoriteCamerasCancel from '@/components/FavoriteCamerasCancel.vue';
import { DIRECTOINARROW } from '@/tools';
import { setSessionStorage } from '@/tools';
// import {  setStorage } from '@/tools';
import { getStorage, setStorage } from '@/tools';
import { CODE_OK } from '@/request/config_code';
import bus from '@/components/js/eventBus';
export default {
  name: 'CollectCom',
  props: {
    ope: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isTree: true, // 是否为树结构
      favoriteSortShow: false, // 收藏夹摄像机排序显隐
      favoriteCancelShow: false,
      favoriteDataList: [], // 摄像机排序数据
      turnVisible: false,
      favoritVisible: false,
      isEdit: false,
      folderId: '',
      folderName: '',
      visibleGroupSetting: false,
      folderProId: '',
      add: false,
      addShow: true,
      myCollect: true,
      clickMyCollect: true,
      folderData: {},
    };
  },
  computed: {
    ...mapState({
      cameraCollectList: (state) => state.collect.cameraCollectList,
      screenShotSearchParams: (state) => state.resource.screenShotSearchParams,
      amapIns: (state) => state.home.amapIns,
      homeFolderCamera: (state) => state.home.homeFolderCamera,
    }),
  },
  watch: {},
  components: {
    newTurn,
    FavoritToggle,
    CameraGroupSetting,
    favoriteTree,
    favoriteCamerasSort,
    favoriteCamerasCancel,
  },
  mounted() {
    // this.getCollectList();
    if (!this.isTree) {
      this.getHomeDefaultCamera();
    }
  },
  beforeMount() {
    bus.$on('myFolder', this.openMyFolder);
    bus.$on('otherFolder', this.openOtherFolder);
    bus.$on('clearStates', this.clear);
  },
  methods: {
    ...mapMutations([
      'setCameraTab',
      'setScreenShotSearchParams',
      'setSelectCameraList',
      'setSessionCurrentCamera',
    ]),
    ...mapActions([
      'getCollectList',
      'deleteFolderCollectItem',
      'getScreenShotInfo',
      'getHomeDefaultCamera',
      'getHomeFolderCamera',
      'deleteFolderAction',
    ]),
    // 上移
    // eslint-disable-next-line no-unused-vars
    upHandle(folder, item, index, cameraIndex) {
      if (cameraIndex === 0) {
        return;
      }
      let tempItem = folder.cameraList[cameraIndex - 1];
      let copyItem = JSON.parse(JSON.stringify(item));
      let tempSort = copyItem.sort;
      item.sort = tempItem.sort;
      tempItem.sort = tempSort;
      this.$set(folder.cameraList, cameraIndex - 1, item);
      this.$set(folder.cameraList, cameraIndex, tempItem);
      this.$forceUpdate();
      let params = {
        favoriteId: item.favoriteId,
        folderId: folder.folderId,
        sort: tempSort,
      };

      this.$api.home.moveUpFavorite(params).then((res) => {
        const { code } = res;
        if (code !== CODE_OK) {
          this.$message.error('接口调用失败');
        }
      });
    },
    // 下移
    // eslint-disable-next-line no-unused-vars
    downHandle(folder, item, index, cameraIndex) {
      if (cameraIndex === folder.cameraList.length - 1) {
        return;
      }
      let tempItem = folder.cameraList[cameraIndex + 1];
      let copyItem = JSON.parse(JSON.stringify(item));
      let tempSort = copyItem.sort;
      item.sort = tempItem.sort;
      tempItem.sort = tempSort;
      this.$set(folder.cameraList, cameraIndex + 1, item);
      this.$set(folder.cameraList, cameraIndex, tempItem);
      this.$forceUpdate();
      let params = {
        favoriteId: item.favoriteId,
        folderId: folder.folderId,
        sort: copyItem.sort,
      };
      this.$api.home.moveDownFavorite(params).then((res) => {
        const { code } = res;
        if (code !== CODE_OK) {
          this.$message.error('接口调用失败');
        }
      });
    },
    subName(name) {
      return name.length > 10 ? name.slice(0, 10) + '...' : name;
    },
    cancelCollectHandle(item, folderId) {
      if (!this.hasPermission(109210101014)) {
        this.permissionWarning();
        return;
      }

      this.$confirm({
        title: '提示',
        content: '确认取消收藏该路摄像机?',
        onOk: () => {
          let params = {
            cameraId: item.cameraId,
            folderId,
          };
          let obj = {
            params,
            instructionsDel: {
              module: '重点关注',
              page: '收藏列表',
              feature: '取消收藏',
              description: `取消收藏: ${item.cameraName}`,
            },
          };
          this.deleteFolderCollectItem(obj).then((data) => {
            if (data.code === 200) {
              item.folderId = folderId;
              this.$set(item, 'hasFavorited', false);
              setSessionStorage('currentCamera', item);
              this.setSessionCurrentCamera(item);
              this.$message.success('取消收藏成功！');
            }
          });
        },
        onCancel: () => {},
      });
    },
    getTitle(item) {
      return `桩号:${item.kmHmPile}.方向:${
        DIRECTOINARROW[item.derection]
      },兴趣点名称:${item.poiName}`;
    },
    newTurnHandle() {
      if (!this.hasPermission(109210101015)) {
        this.permissionWarning();
        return;
      }

      this.turnVisible = true;
      this.setCameraTab('collect');
      this.setSelectCameraList(this.cameraCollectList);
    },
    closeDialogHandle() {
      this.turnVisible = false;
    },
    selectShot(item) {
      if (this.ope) {
        // console.log(item);
        if (item.deleteStatus === 1) {
          this.$message.error('摄像机已删除，无法调阅该摄像机!');
          return;
        }
        if (item.otherOrganizationId === 1) {
          this.$message.error('暂无观看权限！');
          return;
        }
        let cameraInfo = item;
        cameraInfo.lonlat = [cameraInfo.longitude, cameraInfo.latitude];
        /*   if (cameraInfo.cameraStatus == 1) { */
        this.amapIns.cameraInfoWindowShow({
          data: {
            position: [cameraInfo.longitude, cameraInfo.latitude],
            onlineStatus: cameraInfo.status,
            cameraName: cameraInfo.cameraName,
            cameraId: cameraInfo.cameraId,
            cameraNum: cameraInfo.cameraNum,
            organizationId: cameraInfo.organizationId,
            regionCode: cameraInfo.regionCode,
            roadId: cameraInfo.roadId,
            kmPile: cameraInfo.kmPile,
            hmPile: cameraInfo.hmPile,
            classify: cameraInfo.classify,
            cameraType: cameraInfo.cameraType,
            zoomlevel: cameraInfo.zoomlevel,
            source: cameraInfo.source,
            khPile: cameraInfo.kmHmPile,
            collectFlag: 1,
            /* resolutionValue: cameraInfo.organizationId.startsWith('9901004075')
              ? 1
              : 0, */
            resolutionValue:
              cameraInfo.transcodingId === '1354354698693513216' ? 1 : 0,
            lonlat: [cameraInfo.longitude, cameraInfo.latitude],
          },
        });
        if (this.$g.isDepart) {
          this.amapIns.addActCameraIcon(cameraInfo);
        } else {
          this.amapIns.addVideoMarker(cameraInfo);
        }
        /*   } else {
          this.$message.error('该摄像机不在线，不能拉流！');
        } */
        return;
      }
      let data = {
        ...this.screenShotSearchParams,
        ...{ cameraId: item.cameraId },
      };
      this.setScreenShotSearchParams(data);
      this.getScreenShotInfo(data);
    },
    addFavoritHandle() {
      this.add = true;
      if (!this.hasPermission(109210101010)) {
        this.permissionWarning();
        return;
      }

      this.favoritVisible = true;
      this.isEdit = false;
      this.folderId = '';
      this.folderName = '';
    },
    // 组织树修改收藏夹
    editFavorit() {
      // bus.$emit('openEditFavorit');
      this.$refs.folderTree.handleEditFavorit(this.folderData);
    },
    // 组织树排序摄像机
    camerasSort() {
      this.$refs.folderTree.favoriteCamerasSort(this.folderData);
    },
    // 批量取消收藏
    cancel() {
      if (this.folderData.cameraNumber < 1) {
        this.$message.error('收藏夹下无摄像机！');
        return;
      }
      this.$refs.folderTree.batchCancel(this.folderData);
    },
    projector() {
      this.$refs.folderTree.projectHandle(this.folderData);
      console.log('this.folderData', this.folderData)
    },
    favoriteCamerasCancelOpen(val) {
      this.favoriteCancelShow = true;
      this.favoriteDataList = val;
      console.log('this.favoriteDataList', this.favoriteDataList);
    },
    getFolderData(val) {
      console.log('点击组织的参数', val);
      this.folderData = val;
      this.folderProId = val.folderId
    },
    editTreeFavorite() {
      this.$refs.folderTree.handleEditFavorit(this.folderData);
    },
    handleEditFavorit(item) {
      if (!this.hasPermission(109210101011)) {
        this.permissionWarning();
        return;
      }

      this.favoritVisible = true;
      this.isEdit = true;

      this.folderId = item.folderId;
      this.folderName = item.folderName;
      console.log(item, this.folderId, this.folderName);
    },
    closeFavorit() {
      this.favoritVisible = false;
    },
    sureFavorit() {
      this.$refs['folderTree'].sureFavorit();
      this.favoritVisible = false;
      this.$message.success(`${this.isEdit ? '修改' : '新增'}收藏夹成功`);
    },
    expandFolder(folder) {
      if (!folder.show) {
        let data = {
          folder: folder.folderId,
        };
        this.getHomeFolderCamera(data).then(() => {
          folder.show = !folder.show;
        });
        return;
      }
      folder.show = !folder.show;
    },
    deleteFolder() {
      if (!this.hasPermission(109210101012)) {
        this.permissionWarning();
        return;
      }

      let data = {
        folderId: this.folderData.folderId,
        whetherDefaultFolder: 0,
        folderName: this.folderData.folderName,
      };
      console.log('data123', data);
      this.$confirm({
        title: '提示',
        content: '确认删除该收藏文件夹吗?',
        onOk: () => {
          // this.deleteFolderAction(data);
          let objParams = {
            data,
            instructionsDel: {
              module: '重点关注',
              page: '收藏列表',
              feature: '删除收藏夹',
              description: `删除收藏夹:  ${data.folderName}`,
            },
          };
          this.$api.home.deleteFolder(objParams).then((res) => {
            if (res.code !== 200) {
              this.$message.error('删除收藏文件夹失败');
              return;
            } else {
              this.$message.success('删除收藏文件夹成功！');
              this.$refs.folderTree.fetchTree();
              this.clear();
            }
          });
        },
        onCancel: () => {},
      });
    },
    //投屏
    projectHandle(folder, e) {
      e.stopPropagation();
      let cameraInfoStore = getStorage('collectCamera');
      console.log('cameraInfoStorecameraInfoStore', cameraInfoStore);
      // if (cameraInfoStore && JSON.stringify(cameraInfoStore) != '{}') {
      //   this.$message.error('每个收藏夹摄像机只能同时投屏一次！');
      //   return;
      // }
      this.visibleGroupSetting = true;
      this.folderProId = folder.folderId;
    },
    currentScreenHandle(curScree) {
      alert(4444)
      let currentScreen = curScree.mode;
      let tit = curScree.litTit;
      let interval = curScree.interval;
      // this.visibleGroupSetting = false;
      // console.log(currentScreen, tit, this.projectDataList);
      let { href } = this.$router.resolve({
        path: `/${currentScreen}-window${tit}`,
      });
      let paramsObj = {
        folder: this.folderProId,
      };
      console.log('href', href, paramsObj)
      this.$refs['cameraGroupSetting'].setLoading(true);
      this.getHomeFolderCamera(paramsObj).then((res) => {
        if (res.code === CODE_OK) {
          this.$refs['cameraGroupSetting'].setLoading(false);
          this.projectDataList = res.data
            .filter((item) => item.deleteStatus !== 1)
            .map((item) => {
              item.cameraName = item.cameraNames;
              return item;
            });
          this.visibleGroupSetting = false;
          window.open(`${href}?isCollect=1`, '_blank');
          setStorage('collectCamera', { interval, arr: this.projectDataList });
        }
      });
    },
    closeGroupSettingHandle() {
      this.visibleGroupSetting = false;
    },
    // 摄像机排序开启
    favoriteCamerasSortOpen(val) {
      this.favoriteSortShow = true;
      this.favoriteDataList = val;
    },
    // 关闭摄像机排序
    sortCancel() {
      this.favoriteSortShow = false;
      this.favoriteCancelShow = false;
    },
    // 更新收藏夹数据
    updateFavorite() {
      this.favoriteSortShow = false;
      this.getHomeDefaultCamera();
    },
    openMyFolder() {
      this.addShow = false;
      this.myCollect = false;
      this.clickMyCollect = true;
    },
    openOtherFolder() {
      this.addShow = true;
      this.clickMyCollect = false;
      this.myCollect = false;
    },
    clear() {
      this.addShow = true;
      this.clickMyCollect = true;
      this.myCollect = true;
      this.folderData = {};
    },
    // 更新收藏夹数据
    success() {
      this.getHomeDefaultCamera();
    },
  },
};
</script>

<style lang="less" scoped>
.collect-com {
  .folder-wrapper {
    height: calc(100% - 100px);
    margin-right: -0.5rem;
    overflow-y: overlay;
    padding-right: 0.5rem;
  }
  .folder-wrapper-tree {
    height: calc(100% - 100px);
    margin-right: -0.5rem;
    // padding-right: 0.5rem;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}
</style>
<style scoped>
.title-con {
  border-bottom: 1px dashed rgba(40, 69, 106, 1);
}
/* .collect-com ::-webkit-scrollbar {
  display: none;
} */
.icon-project {
  background: url('../assets/resource/icon-project.png') no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 13px;
}
.line-througth {
  text-decoration: line-through;
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: rgb(151, 151, 151);
}
.favorite-sort {
  width: 100%;
  height: 100%;
  z-index: 999;
}
</style>
